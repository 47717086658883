import { createRouter, createWebHistory } from 'vue-router'
import HomeView from '../views/HomeViewPage.vue'
import PackagesView from '../views/PackagesView.vue'
import ChannelsView from '../views/ChannelsView.vue'
import VideosView from '../views/VideosView.vue'
import ProfileView from '../views/ProfileView.vue'
import SignInView from '../views/SignInView.vue'
import RegisterView from '../views/RegisterView.vue'
import RegisterUserView from '../views/RegisterUserView.vue'
import PackagesDetailsView from '../views/PackagesDetailsView.vue'
import ChangePassword from '../views/ChangePassword.vue'
import ForgottenPassword from '../views/ForgottenPassword.vue'
import CreditCards from '../views/CreditCards.vue'
import PageNotFound from '../views/PageNotFound.vue'
import ContactUs from '../views/ContactUsView.vue'
import CardAddSuccess from '../views/CardAddSuccess.vue'
import CardAddFail from '../views/CardAddFail.vue'
import PostpaidApplication from '../views/PostpaidApplication.vue'


const routes = [
  {
    path: '/',
    name: 'HomeView',
    component: HomeView,
    props: true
  },
  {
    path: '/paketi',
    name: 'PackagesView',
    component: PackagesView,
    props: true
  },
  {
    path: '/kanali',
    name: 'ChannelsView',
    component: ChannelsView,
    props: true
  },
  {
    path: '/videoteke',
    name: 'VideosView',
    component: VideosView,
    props: true
  },
  {
    path: '/profil',
    name: 'ProfileView',
    component: ProfileView,
    props: true
  },
  {
    path: '/prijavi-se',
    name: 'SignInView',
    component: SignInView,
    props: true
  },
  // {
  //   path: '/registruj-se',
  //   name: 'RegisterView',
  //   component: RegisterView,
  //   props: true
  // },
  {
    path: '/registracija',
    name: 'RegisterUserView',
    component: RegisterUserView,
    props: true
  },
  // {
  //   path: '/detalji-paketa',
  //   name: 'PackagesDetailsView',
  //   component: PackagesDetailsView,
  //   props: true
  // },
  {
    path: '/promijeni-lozinku',
    name: 'ChangePassword',
    component: ChangePassword,
    props: true
  },
  {
    path: '/zaboravljena-lozinka',
    name: 'ForgottenPassword',
    component: ForgottenPassword,
    props: true
  },
  // {
  //   path: '/kartice',
  //   name: 'CreditCards',
  //   component: CreditCards,
  //   props: true
  // },
  {
    path: '/kontaktiraj-nas',
    name: 'ContactUs',
    component: ContactUs,
    props: true
  },
  {
    path: '/posalji-zahtjev',
    name: 'PostpaidApplication',
    component: PostpaidApplication,
    props: true
  },
  {
    path: '/:pathMatch(.*)*',
    name: 'PageNotFound',
    component: PageNotFound
  },
  {
    path: '/kartica-error',
    name: 'CardAddFail',
    component: CardAddFail
  },
  {
    path: '/kartica-success',
    name: 'CardAddSuccess',
    component: CardAddSuccess
  },
  
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  // history: createWebHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  }
})

export default router
