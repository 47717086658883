<template>
  

</template>

<script>

export default {
  data(){
    return{

    }
  },

  mounted(){
    // console.log(this.$vuetify.display.width);
  }


}
</script>

<style>

</style>