<template>
  <div class="contactUs">
    <v-snackbar v-model="this.sendSuccess" :timeout="2000" location="top center" transition="slide-y-transition" 
      height="80" variant="elevated" color="success" elevation="24">
       <p class="message text-center">Uspješno poslan zahtjev.</p> 
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="this.sendSuccess = false">
            <v-icon size="large">mdi-close</v-icon>
          </v-btn>
        </template>
    </v-snackbar>
    <v-snackbar v-model="this.sendFail" :timeout="2000" location="top center" transition="slide-y-transition" 
      height="80" variant="elevated" color="#f58220" elevation="24">
       <p class="message text-center">Postoji nalog sa ovom e-mail adresom.</p> 
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="this.sendFail = false">
            <v-icon size="large">mdi-close</v-icon>
          </v-btn>
        </template>
    </v-snackbar>
    <v-container class="pa-6">
      <v-row justify="center">
        <v-col cols="12" md="6" lg="6" xl="5" :style="biggerScreen ? 'background-color: #ffff; border-radius: 12px; box-shadow: 0px 0px 6px rgba(0, 0, 0, 0.16);' : ''">
          <div class="form">
            <v-container :style="biggerScreen ? 'padding: 30px 15px 50px 15px;' : ''">
              <h1 :style="!biggerScreen ? 'text-align:center;' : ''" class="text-4xl font-bold">Kontaktiraj nas</h1>
              <br>
              <v-row>
                <v-col cols="12" class="pb-0">
                  <v-text-field
                    label="Ime i prezime"
                    clearable
                    prepend-inner-icon="mdi-account-outline"
                    v-model="this.currentUserContact.name"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" lg="6" xl="6" class="pt-0 pb-0">
                  <v-text-field
                    label="Ulica i broj"
                    clearable
                    prepend-inner-icon="mdi-map-search-outline"
                    v-model="this.currentUserContact.street"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="6" xl="6" class="pt-0 pb-0">
                  <v-text-field
                    label="Grad"
                    clearable
                    prepend-inner-icon="mdi-city-variant-outline"
                    v-model="this.currentUserContact.city"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" md="6" lg="6" xl="6" class="pt-0 pb-0">
                  <v-text-field
                    label="Telefon"
                    clearable
                    prepend-inner-icon="mdi-cellphone"
                    v-model="this.currentUserContact.phone"
                    :rules="[rules.required]"
                  ></v-text-field>
                </v-col>
                <v-col cols="12" md="6" lg="6" xl="6" class="pt-0 pb-0">
                  <v-text-field
                    label="Email"
                    clearable
                    prepend-inner-icon="mdi-email-outline"
                    v-model="this.currentUserContact.email"
                    :rules="[rules.required, rules.email]"
                  ></v-text-field>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-0 pb-0">
                  <v-textarea 
                    prepend-inner-icon="mdi-text"
                    auto-grow
                    v-model="this.currentUserContact.note" 
                    label="Napomena">
                  </v-textarea>
                </v-col>
                <v-col cols="12" class="pt-0 pb-2">
                  <v-checkbox
                    @click="agreedState()"
                    color="error"
                    label="Saglasan sam da se moji podaci prikupljaju i čuvaju"
                  ></v-checkbox>
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="12" class="pt-2 pb-0">
                  <v-btn @click="send" :loading="this.loading" size="large" block class="bg-error text-white" variant="tonal" elevation="3" :disabled="isDisabled">
                  <span class="font-bold">Pošalji</span>
                </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </div>
        </v-col>
      </v-row>
      <v-row justify="center" class="sales-partners">
        <v-col cols="12" align="center" class="mb-6">
          <h1 class="text-4xl font-bold">Partneri u prodaji</h1>
        </v-col>
        <v-col align="center" cols="12" md="6" lg="3" xl="3">
          <v-card max-width="360" color="white" height="140" elevation="4">
              <v-row justify="center">
                <v-col cols="8" class="text-left">
                  <v-card-text>
                    <div class="text-black">
                      <div class="text-xl">
                        DINNET d.o.o.
                      </div>
                      <div class="text-sm">
                        <span><v-icon class="pb-1" size="small">mdi-city</v-icon> Gračanica</span> 
                      </div>
                      <div class="text-sm pt-2">
                        <v-icon class="pb-1" size="small">mdi-phone</v-icon> 035/961-700 <br>
                        <v-icon class="pb-1" size="small">mdi-cellphone</v-icon> 061/660-739
                      </div>
                    </div>
                  </v-card-text>
                </v-col>
                <v-col class="text-right" cols="4">
                  <v-card-title class="text-left pt-4">
                    <img class="logo" height="100" width="100" src="@/assets/images/dinnet.jpg">
                  </v-card-title>
                </v-col>
              </v-row>
          </v-card>
        </v-col>
        <v-col align="center" cols="12" md="6" lg="3" xl="3">
          <v-card max-width="360" color="white" height="140" elevation="4">
              <v-row justify="center">
                <v-col cols="8" class="text-left">
                  <v-card-text>
                    <div class="text-black">
                      <div class="text-xl">
                        TELINEA d.o.o.
                      </div>
                      <div class="text-sm">
                       <span><v-icon class="pb-1" size="small">mdi-city</v-icon> Bihać</span> 
                      </div>
                      <div class="text-sm pt-2">
                        <v-icon class="pb-1" size="small">mdi-phone</v-icon> 037/901-901 <br>
                        <v-icon class="pb-1" size="small">mdi-cellphone</v-icon> 063/281-510
                      </div>
                    </div>
                  </v-card-text>
                </v-col>
                <v-col class="text-right" cols="4">
                  <v-card-title class="text-left pt-4">
                    <img height="80" width="80" src="@/assets/images/telinea.png">
                  </v-card-title>
                </v-col>
              </v-row>
          </v-card>
        </v-col>
        <v-col align="center" cols="12" md="6" lg="3" xl="3">
          <v-card max-width="360" color="white" height="140" elevation="4">
              <v-row justify="center">
                <v-col cols="8" class="text-left">
                  <v-card-text>
                    <div class="text-black">
                      <div class="text-xl">
                        EXCLUSIVE NET d.o.o.
                      </div>
                      <div class="text-sm">
                        <span><v-icon class="pb-1" size="small">mdi-city</v-icon> Cazin</span> 
                      </div>
                      <div class="text-sm pt-2">
                        <v-icon class="pb-1" size="small">mdi-cellphone</v-icon> 062/141-341 <br>
                      </div>
                    </div>
                  </v-card-text>
                </v-col>
                <v-col class="text-right" cols="4">
                  <v-card-title class="text-left pt-4">
                    <img class="logo" height="80" width="80" src="@/assets/images/exclusive.jpg">
                  </v-card-title>
                </v-col>
              </v-row>
          </v-card>
        </v-col>
        <v-col align="center" cols="12" md="6" lg="3" xl="3">
          <v-card max-width="360" color="white" height="140" elevation="4">
              <v-row justify="center">
                <v-col cols="8" class="text-left">
                  <v-card-text>
                    <div class="text-black">
                      <div class="text-xl">
                        SHOPTEL
                      </div>
                      <div class="text-sm">
                        <span><v-icon class="pb-1" size="small">mdi-city</v-icon> Sarajevo</span> 
                      </div>
                      <div class="text-sm pt-2">
                        <v-icon class="pb-1" size="small">mdi-road</v-icon> Ismeta Alajbegovića Šerbe 3 <br>
                        <v-icon class="pb-1" size="small">mdi-phone</v-icon> 033/864-456
                      </div>
                    </div>
                  </v-card-text>
                </v-col>
                <v-col class="text-right" cols="4">
                  <v-card-title class="text-left pt-4">
                    <img class="logo" height="80" width="80" src="@/assets/images/shoptel-logo.png">
                  </v-card-title>
                </v-col>
              </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
 
  </div>
</template>
<script>
import {useUserStore} from '../stores/UserStore'

export default{
  setup(){
    const userStore = useUserStore();
    return {userStore}
  },
  data(){
    return{
      currentUserContact: {
        name: '',
        street: '',
        city: '',
        phone: '',
        email: '',
        note: '',
        agreed: false
      },
      loading: false,
      sendSuccess: false,
      sendFail: false,
      rules:{
          required: value => !!value || "Obavezno polje.",
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            return pattern.test(value) || 'Neispravna e-mail adresa.'
          }
        },
    }
  },

  methods:{
    async send(){
      
      this.loading = true;

      if(this.currentUserContact.agreed == true){
          this.currentUserContact.agreed = "1";
        }else{
          this.currentUserContact.agreed = "0";
      }

      let response = await this.userStore.sendEmail(this.currentUserContact);

      if(response.apiResult[0].result_code == 0){
        this.sendSuccess = true;
        this.currentUserContact.name = null;
        this.currentUserContact.street = null;
        this.currentUserContact.city = null;
        this.currentUserContact.phone = null;
        this.currentUserContact.email = null;
        this.currentUserContact.note = null;
        this.currentUserContact.agreed = false;
        this.loading = false;
      }else{
        this.sendFail = true;
        this.loading = false;
        
      }
    },

    agreedState(){
      this.currentUserContact.agreed = !this.currentUserContact.agreed;
    }
  },

  computed:{

    isDisabled(){
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let validEmail = pattern.test(this.currentUserContact.email);

      if(this.currentUserContact.name == '' || this.currentUserContact.street == '' || this.currentUserContact.city == '' || this.currentUserContact.phone == '' || this.currentUserContact.email == '' || validEmail == false || this.currentUserContact.agreed == false){
        return true;
      }else{
        return false;
      }
    },

    biggerScreen () {
        if(this.$vuetify.display.width >= 960){
          return true;
        }else{
          return false;
        }
      }
  },

  beforeMount(){
      window.scrollTo(0, 0);
  },
}


</script>
<style>
.contactUs{
    font-family: 'Pero-Bold';
    width: 100%;
    height: 1260px;
    /* padding: 120px 300px 0px 300px; */
    padding-top: 60px;
    /* background: rgb(0, 0, 0, 0.2); */
    background-color: rgb(233, 233, 233)
  }

.sales-partners{
  padding-top: 90px;
}


@media only screen and (max-width: 960px){
    .contactUs{
      height: 1710px;
      padding-top: 20px;
    }

    .sales-partners{
      padding-top: 50px;
    }
  }

@media only screen and (min-width: 960px) and (max-width: 1279px){
    .contactUs{
      height: 1300px;
      padding-top: 20px;
    }

    .sales-partners{
      padding-top: 70px;
    }
  }

  .message{
    padding-bottom: 10px;
    font-family: 'Roboto-Bold';
    font-size: 16px;
  }

  .logo{
    border-radius: 6px;
  }
  
/* @media only screen and (min-width: 1280px){
    .form{
      width: max-content;
    }
  } */

</style>