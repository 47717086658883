<template>
    <div class="cardFail">
      <v-container>
        <v-row class="text-center">
          <v-col cols="12">
            <v-card color="transparent" flat class="mt-10">
              <div class="display-6 mt-10 title">
                <span>Desila se greška, pokušaj kasnije.</span>
                <img class="mx-auto mt-8" src="../assets/images/card-warning.png" alt="card-success"/>
              </div>
              <div class="mt-2">
                <router-link class="no-underline" :to="{name: 'ProfileView'}">
                  <v-btn color="black" size="x-large" variant="flat" rounded="2" class="mt-8"><v-icon class="pr-2" color="white" size="x-large">mdi-account-arrow-left</v-icon><span class="font-bold text-white pl-1">Moj nalog</span></v-btn>
                </router-link>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
    </template>
    
    <script>
    export default{
        
    }
    
    </script>
    
    <style>
    
    .cardFail{
      /* font-family: 'Pero-Bold'; */
      font-size: 14px;
      width: 100%;
      height: 720px;
      padding-top: 50px;
      color: white;
      background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));
    }
    
    .title{
        font-family: 'Pero-Bold';
    }
    
    .details{
      font-family: 'Pero-Light';
      font-size: 22px;
    }
    .details span{
        font-family: 'Pero-Bold';
    }
    
    </style>