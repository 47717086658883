<template>
  <div class="flex flex-col min-h-screen">
  <v-app>
    <Navbar/>
    <v-main class="main">
      <router-view/>
    </v-main>
    <Footer/>
  </v-app>
  </div>
</template>

<script>
import Navbar from './components/Navbar.vue'
import Footer from './components/Footer.vue'
import SideBar from './components/SideBar.vue'
import axios from 'axios'
import VueAxios from 'vue-axios'

export default{
  components: {Navbar, Footer, SideBar, axios, VueAxios},
  methods:{
     logOutAfterTwoHours(){
       const userLoginTime = localStorage.getItem('timestamp');

       if(userLoginTime){

        const currentTime = new Date().getTime();
        const twoHoursInMillis = 2 * 60 * 60 * 1000;

        if(currentTime - userLoginTime >= twoHoursInMillis){
          localStorage.removeItem('user');
          localStorage.removeItem('timestamp');
          localStorage.removeItem('packages');
        }

       }
    }
  },

  beforeMount(){
    this.logOutAfterTwoHours();
  }
}

</script>


<style>
@import './assets/fonts.css';
/* #app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
} */

</style>
