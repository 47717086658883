<template>
  <v-app-bar app style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));" dark class="px-10" v-if="biggerScreen">
    <v-container fluid class="nav">
      <v-row align="center"> 
        <v-col cols="1">
          <router-link :to="{name: 'HomeView'}"><img class="mr-1 mt-1" src="@/assets/images/white-logo.svg" alt="Logo" height="35" width="120"></router-link>
        </v-col>
        <v-col cols="9">
          <v-btn variant="text">
            <router-link class="link" tag="li" :to="{name: 'HomeView'}">Početna</router-link>
          </v-btn>
          <v-btn variant="text">
            <router-link class="link" tag="li" :to="{name: 'PackagesView'}">Paketi i uređaji</router-link>
          </v-btn>
          <v-btn variant="text">
            <router-link class="link" tag="li" :to="{name: 'ChannelsView'}">Kanali</router-link>
          </v-btn>
          <v-btn variant="text">
            <router-link class="link" tag="li" :to="{name: 'VideosView'}">Videoteke</router-link>
          </v-btn>
          <v-btn variant="text">
            <router-link class="link" tag="li" :to="{name: 'ContactUs'}">Kontakt</router-link>
          </v-btn>
        </v-col>
        <v-col cols="2" class="mt-1 text-right">
          <!-- <v-menu class="text-left">
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" variant="text" color="white">
                <v-icon size="large" class="mr-1">mdi-account</v-icon>
                <span class="font-bold text-lg">Nalog</span>
              </v-btn>
            </template>
            <v-list>
              <v-list-item>
                <router-link class="login-link text-black text-base" :to="{name: 'ProfileView'}"><v-icon class="mr-1 mb-1 text-black">mdi-account-outline</v-icon>Profil</router-link>
              </v-list-item>
              <v-list-item>
                <router-link class="login-link text-black text-base" :to="{name: 'SignInView'}"><v-icon class="mr-1 mb-1 text-black">mdi-arrow-right-thin-circle-outline</v-icon>Prijavi se</router-link>
              </v-list-item> -->
              <!-- <v-list-item>
                <router-link class="login-link text-red-700 text-base" :to="{name: 'RegisterView'}"><v-icon class="mr-1 mb-1 text-red-700">mdi-account-plus</v-icon>Registruj se</router-link>
              </v-list-item> -->
            <!-- </v-list>
          </v-menu> -->
          <v-btn variant="text">
            <router-link class="profile-link" tag="li" :to="{name: 'ProfileView'}"><v-icon class="mr-1">mdi-account</v-icon>Moj Nalog</router-link>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-app-bar>
  <v-app-bar v-else style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));" dark class="px-3">
    <template v-slot:append>
      <v-app-bar-nav-icon color="white" @click="this.drawer = !this.drawer"></v-app-bar-nav-icon>
    </template>
    <template v-slot:prepend>
      <router-link :to="{name: 'HomeView'}"><img src="@/assets/images/white-logo.svg" alt="Logo" height="40" width="80"></router-link>
    </template>
    <!-- <template v-slot:append>
      <v-menu class="text-left">
        <template v-slot:activator="{ props }">
          <v-btn v-bind="props" variant="text">q
            <v-icon size="large" class="mr-1">mdi-account</v-icon></v-btn>
        </template>
        <v-list>
          <v-list-item>
            <router-link class="login-link text-black text-sm" :to="{name: 'ProfileView'}">
              <v-icon class="mr-1 mb-1 text-black">mdi-account-outline</v-icon>Profil
            </router-link>
          </v-list-item>
          <v-list-item>
            <router-link class="login-link text-black text-sm" :to="{name: 'SignInView'}">
              <v-icon class="mr-1 mb-1 text-black">mdi-arrow-right-thin-circle-outline</v-icon>Prijavi se
            </router-link>
          </v-list-item>
          <v-list-item>
            <router-link class="login-link text-red-800 text-sm" :to="{name: 'RegisterView'}">
              <v-icon class="mr-1 mb-1 text-red-800">mdi-account-plus</v-icon>Registruj se
            </router-link>
          </v-list-item>
        </v-list>
      </v-menu>
    </template> -->
  </v-app-bar>
  <v-navigation-drawer
    v-if="!biggerScreen"
    v-model="drawer"
    color="#b10d28"
    location="right"
    elevation="4"
    >
    <v-list density="compact" nav>
      <v-list-item class="mt-4">
        <v-btn variant="text">
          <router-link class="no-underline text-white text-lg font-bold" tag="li" :to="{name: 'HomeView'}">
            <v-icon class="pb-1 mr-2">mdi-home</v-icon>
            <span class="mobile-link">Početna</span>
          </router-link>
        </v-btn>
      </v-list-item>
      <v-list-item>
        <v-btn variant="text">
          <router-link class="no-underline text-white text-lg font-bold" tag="li" :to="{name: 'PackagesView'}">
            <v-icon class="mr-2">mdi-layers</v-icon>
            <span class="mobile-link">Paketi i uređaji</span>
          </router-link>
        </v-btn>
      </v-list-item>
      <v-list-item>
        <v-btn variant="text">
          <router-link class="no-underline text-white text-lg font-bold" tag="li" :to="{name: 'ChannelsView'}">
            <v-icon class="mr-2">mdi-television-box</v-icon>
            <span class="mobile-link">Kanali</span>
          </router-link>
        </v-btn>
      </v-list-item>
      <v-list-item>
        <v-btn variant="text">
          <router-link class="no-underline text-white text-lg font-bold" tag="li" :to="{name: 'VideosView'}">
            <v-icon class="mr-2">mdi-video</v-icon>
            <span class="mobile-link">Videoteke</span>
          </router-link>
        </v-btn>
      </v-list-item>
      <v-list-item>
        <v-btn variant="text">
          <router-link class="no-underline text-white text-lg font-bold" tag="li" :to="{name: 'ContactUs'}">
            <v-icon class="mr-2">mdi-email-fast</v-icon>
            <span class="mobile-link">Kontakt</span>
          </router-link>
        </v-btn>
      </v-list-item>
      <hr class="solid">
      <!-- <v-list-item>
          <v-btn variant="text">
            <router-link class="no-underline text-white text-lg font-bold" :to="{name: 'ProfileView'}">
              <v-icon class="mr-2">mdi-account</v-icon>Profil
          </router-link>
          </v-btn>
          </v-list-item>
          <v-list-item>
            <v-btn variant="text">
            <router-link class="no-underline text-white text-lg font-bold" :to="{name: 'SignInView'}">
              <v-icon class="mr-2">mdi-arrow-right-thin-circle-outline</v-icon>Prijavi se
            </router-link>
            </v-btn>
          </v-list-item> -->
          <v-list-item class="pt-2">
            <v-btn variant="text">
            <router-link class="no-underline text-white text-lg font-bold" :to="{name: 'ProfileView'}">
              <v-icon class="mr-2 pb-1">mdi-account</v-icon>
              <span class="mobile-link">Moj Nalog</span>
            </router-link>
            </v-btn>
          </v-list-item>
          <!-- druga verzija -->
          <!-- <v-list-item>
            <v-btn variant="text">
            <router-link class="no-underline text-white text-lg font-bold" :to="{name: 'RegisterView'}">
              <v-icon class="mr-2">mdi-account-plus</v-icon>Registruj se
            </router-link>
            </v-btn>
          </v-list-item> -->
    </v-list>
  </v-navigation-drawer>
</template>

<script>
import {useUserStore} from '../stores/UserStore';
import { storeToRefs } from 'pinia';
import { watch, ref } from 'vue'
import { useRouter } from 'vue-router'

export default {
  setup(){
    const userStore = useUserStore();
    
    // const {isLoggedIn} = storeToRefs(userStore);
    
    // watch(isLoggedIn, ()=> {
    //   // console.log("Logged status changed!");
    // })

    let drawer = ref(false);
    let currentUser = ref(null);

    return {userStore, drawer, currentUser}
  },

  computed:{
    biggerScreen () {
      if(this.$vuetify.display.width >= 960){
        return true;
      }else{
        return false;
      }
    }
  }
}
</script>

<style>
.link{
  font-family: 'Pero-Bold';
  color: white;
  text-decoration: none;
  font-weight: 800;
  font-size: 17px;
  text-transform: uppercase;
  transition: 0.5s ease-in-out;
  padding-top:5px;
  padding-bottom: 5px;
}

/* .link:hover{ */
  /* color:#B50A2F; */
  /* color: black;
} */

.login-link{
  color: white;
  text-decoration: none;
  font-weight: 700;
  font-size: 17px;
  text-transform: uppercase;
  transition: 0.5s ease-in-out;
  padding-top:2px;
}

.login-link:hover{
  color:#B50A2F;
}

.profile-link{
  font-family: 'Pero-Bold';
  color: white;
  text-decoration: none;
  font-weight: 800;
  font-size: 17px;
  text-transform: uppercase;
  transition: 0.5s ease-in-out;
  padding-bottom: 2px;
  padding-top:2px;
}

.mobile-link{
  font-family: 'Pero-Bold';
}


.link.router-link-active{
  /* color: #B50A2F; */
  text-decoration: dotted underline #f58220 4px;
  text-underline-offset: 0.3em;

}

.mobile-nav{
  display: flex;
  justify-content: space-between;
  align-items: center;
}

</style>