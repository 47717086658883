<template>
  <div class="profile" v-if="this.currentUser != null">
    <v-snackbar v-model="this.enableRenewPackageSuccess" :timeout="3000" location="top center" transition="slide-y-transition" 
      height="80" variant="elevated" color="success" elevation="24" class="text-center">
       <p class="message">Uspješno si aktivirao automatsko obnavljanje pretplate.</p> 
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="this.enableRenewPackageSuccess = false">
            <v-icon size="large">mdi-close</v-icon>
          </v-btn>
        </template>
    </v-snackbar>
    <v-snackbar v-model="this.disableRenewPackageSuccess" :timeout="3000" location="top center" transition="slide-y-transition" 
      height="80" variant="elevated" color="success" elevation="24" class="text-center">
       <p class="message">Uspješno si odjavio automatsko obnavljanje pretplate.</p> 
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="this.disableRenewPackageSuccess = false">
            <v-icon size="large">mdi-close</v-icon>
          </v-btn>
        </template>
    </v-snackbar>
    <v-dialog
        v-model="this.statusNotification"
        transition="dialog-top-transition"
        width="auto"
      >
        <v-card>
          <v-toolbar
            :color="this.statusColor"
            title="Obavještenje"
          >
            <v-toolbar-items>
              <v-btn variant="text" @click="this.statusNotification = false">
                <v-icon size="x-large" class="text-white">mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>
          <v-card-text class="text-center">
            <div class="text-xl pa-3">{{this.statusNotificationMessage}}</div>
            <div class="text-base">{{this.statusDescMessage}}</div>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn variant="tonal" @click="this.statusNotification = false" class="delete-btn">
              <span class="text-grey-700 pt-1">Zatvori</span>    
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    <v-snackbar v-model="this.cardInitError" :timeout="2000" location="top center" transition="slide-y-transition" 
      height="80" variant="elevated" color="#f58220" elevation="24">
       <p class="message">Desila se greška, pokušaj kasnije.</p> 
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="this.cardInitError = false">
            <v-icon size="large">mdi-close</v-icon>
          </v-btn>
        </template>
    </v-snackbar>
    <v-snackbar v-model="this.deleteDeviceError" :timeout="2000" location="top center" transition="slide-y-transition" 
      height="80" variant="elevated" color="#f58220" elevation="24">
       <p class="message">Desila se greška, pokušaj kasnije.</p> 
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="this.deleteDeviceError = false">
            <v-icon size="large">mdi-close</v-icon>
          </v-btn>
        </template>
    </v-snackbar>
    <v-snackbar v-model="this.removeCardError" :timeout="2000" location="top center" transition="slide-y-transition"
      height="80" variant="elevated" color="#f58220" elevation="24">
       <p class="message">Desila se greška prilikom uklanjanja kartice, pokušaj ponovo kasnije.</p> 
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="this.removeCardError = false">
            <v-icon size="large">mdi-close</v-icon>
          </v-btn>
        </template>
    </v-snackbar>
    <v-snackbar v-model="this.addPromoCodeSuccess" :timeout="3000" location="top center" transition="slide-y-transition" 
      height="80" variant="elevated" color="success" elevation="24" class="text-center">
       <p class="message">Uspješno si dodao promo kod.</p> 
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="this.addPromoCodeSuccess = false">
            <v-icon size="large">mdi-close</v-icon>
          </v-btn>
        </template>
    </v-snackbar>
    <v-snackbar v-model="this.addPromoCodeError" :timeout="3000" location="top center" transition="slide-y-transition" 
      height="80" variant="elevated" color="#f58220" elevation="24" class="text-center">
       <p class="message">Desila se greška, provjerite validnost koda.</p> 
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="this.addPromoCodeError = false">
            <v-icon size="large">mdi-close</v-icon>
          </v-btn>
        </template>
    </v-snackbar>
    <v-container>
      <v-row justify="space-around" class="pb-5">
        <v-col v-if="biggerScreen" md="4" lg="3" xl="2">
          <h1 class="text-black text-3xl font-semibold"><span class="text-red-700">MOVETV</span> NALOG</h1>
        </v-col>
        <v-col xs="12" sm="12" md="8" lg="8" xl="7" class="text-center profile-section">
          <h1 v-if="this.currentUser.crm_info.Name != null" class="text-black text-center font-semibold text-2xl">
            <v-icon size="large" color="red-700" class="pr-2 pb-1">mdi-account-box</v-icon>
              {{ this.currentUser.crm_info.Name}}
            <!-- <v-chip v-if="this.currentUser.crm_info.VcceState.includes('Active')" label color="success" class="ml-2">
              <v-icon class="pr-2">mdi-check-circle</v-icon>
              <span class="font-bold" style="padding-top:2px"> Aktivan </span>
            </v-chip>
            <v-chip v-if="this.currentUser.crm_info.VcceState.includes('Suspended')" label color="warning" class="ml-2">
              <v-icon class="pr-2">mdi-close-circle</v-icon>
              <span class="font-bold" style="padding-top:2px"> Suspendovan </span>
            </v-chip> -->
          </h1>
          <h1 v-else class="text-black text-center font-semibold text-2xl">
            <v-icon size="large" color="red-700" class="pr-2 pb-1">mdi-account-box</v-icon>
            {{ this.currentUser.ott_customer_info.customerName}}
          </h1>
          <div v-if="this.currentUser.ott_email" class="text-black text-center acc-details mt-4">
            <v-chip label color="red">
              <v-icon class="pr-2">mdi-email-outline</v-icon>
              <span class="text-red font-bold"> {{ this.currentUser.ott_email }} </span>
            </v-chip>
          </div>
          <div class="text-center mt-3">
            <v-chip v-if="this.currentUser.crm_info.VcceState != null && this.currentUser.crm_info.VcceState.includes('Active')" label color="success">
              <v-icon class="pr-2">mdi-check-circle</v-icon>
              <span class="font-semibold" style="padding-top:2px"> Aktivan </span>
            </v-chip>
            <v-chip v-if="this.currentUser.crm_info.VcceState != null && (this.currentUser.crm_info.VcceState == 'POS-Suspended' || this.currentUser.crm_info.VcceState == 'PRE-Suspended')" label color="warning">
              <v-icon class="pr-2">mdi-close-circle</v-icon>
              <span class="font-semibold" style="padding-top:2px"> Neaktivan </span>
          </v-chip>
          <v-chip v-if="this.currentUser.crm_info.VcceState != null && this.currentUser.crm_info.VcceState == 'UNI-Suspended'" label color="warning">
              <v-icon class="pr-2">mdi-clock-remove-outline</v-icon>
              <span class="font-semibold" style="padding-top:2px"> Istekao promo period </span>
          </v-chip>
          </div>
          <v-divider color="red-800" class="mt-5"></v-divider>
          <!-- </v-card> -->
          <div v-if="biggerScreen" class="text-center mt-2">
            <v-btn @click="this.showTransactions = true" variant="tonal" class="bg-error mt-2 mb-2 w-55 delete-btn mr-2">
              <v-icon size="large" class="pr-3 text-light">mdi-cash-clock</v-icon><span class="font-bold text-light">Prikaži transakcije</span>    
            </v-btn>
            <router-link class="no-underline" :to="{name: 'ChangePassword'}">
              <v-btn class="bg-error w-55 delete-btn" variant="tonal">
                <v-icon size="large" class="pr-3 text-light">mdi-lock-reset</v-icon><span class="font-bold text-light">Promijeni lozinku</span>    
              </v-btn>
            </router-link>
            <v-btn color="#b10d28" variant="outlined" @click="this.logOutDialog = true" class="ml-2">
              <v-icon size="large" class="pr-3 text-red-700">mdi-logout</v-icon><span class="font-bold text-red-700">Odjavi se</span>    
            </v-btn>
          </div>
          <div v-else class="text-center mt-2">
            <v-row justify="center">
              <v-col cols="auto" style="padding: 3px;">
                <v-btn @click="this.showTransactions = true" variant="tonal" class="bg-error delete-btn mt-2" size="small">
                  <v-icon size="large" class="pr-3 text-light">mdi-cash-clock</v-icon><span class="font-bold text-light">Moje transakcije</span>    
                </v-btn>
              </v-col>
              <v-col cols="auto" style="padding: 3px">
                <router-link class="no-underline" :to="{name: 'ChangePassword'}">
                  <v-btn class="bg-error delete-btn mt-2" variant="tonal" size="small">
                    <v-icon size="large" class="pr-3 text-light">mdi-lock-reset</v-icon><span class="font-bold text-light">Promijeni lozinku</span>    
                  </v-btn>
                </router-link>
              </v-col>
            </v-row>
            <v-row justify="center">
              <v-btn color="#b10d28" variant="outlined" @click="this.logOutDialog = true" class="mt-5">
                <v-icon size="large" class="pr-3 text-red-700">mdi-logout</v-icon><span class="font-bold text-red-700">Odjavi se</span>    
              </v-btn>
            </v-row>
          </div>
        </v-col>
      </v-row>
      <v-divider class="mt-6"></v-divider>
      <v-row justify="space-around" class="mt-7 mb-10 pt-5">
      <v-col v-if="biggerScreen" md="4" lg="3" xl="2">
        <h1 class="text-black text-3xl font-semibold"><span class="text-red-700">MOJE</span> PRETPLATE</h1>
      </v-col>
      <v-col xs="12" sm="12" md="8" lg="8" xl="7" class="text-center">
        <v-chip v-if="!biggerScreen" class="mb-8"  label text-color="white">
          <v-icon start icon="mdi-account-details"></v-icon>
          <span class="device-title fs-6">Moje pretplate</span>
        </v-chip>
        <!-- Verzija sa paketima iz naseg sistema-->
        <v-row v-if="this.currentUserPackages != null && this.filteredPackages.length != 0" justify="space-around">
        <v-col :cols="biggerScreen ? '8' : '12'" v-for="item in this.filteredPackages" :key="item.id">
        <v-card 
          class="mb-1 pa-2"
          max-height="210px"
          variant="tonal"
          elevation="3"
          style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
        >
          <v-card-title>
            <h1 class="fs-1 fw-bold text-white">{{ item.package_name.replace('Videoteka', '') }}</h1>
          </v-card-title>
          <v-card-text class="text-white"><span class="card-details">Datum isteka: </span><span class="card-details">{{ item.getPackageExpDate }}</span> </v-card-text>
          <div v-if="item.id != '0'">
            <v-chip v-if="item.isRecurringEnabled == '1'" color="success" variant="flat" class="mb-1">
              <v-icon start icon="mdi-check-circle"></v-icon>
                Automatsko obnavljanje
            </v-chip>
            <v-chip v-else color="error" variant="flat" class="mb-3">
              <v-icon start icon="mdi-close-circle"></v-icon>
                Automatsko obnavljanje
            </v-chip>
            <v-card-actions v-if="item.isCancelPossible == '1' || isPrepaid == false" class="justify-center">
              <v-spacer />
              <v-btn v-if="item.isRecurringEnabled == '1'" class="bg-black" elevation="3" @click="this.openRemoveSubDialog(item.id)">
                <v-icon size="large" class="text-light">mdi-autorenew</v-icon><span class="font-bold text-light pl-1">Odjavi</span>
              </v-btn>
              <v-btn v-else class="bg-success" elevation="3" @click="this.openEnableSubDialog(item.id)">
                <v-icon size="large" class="text-light">mdi-autorenew</v-icon><span class="font-bold text-light pl-1">Obnovi</span>
              </v-btn>
              <v-spacer/>
            </v-card-actions>
          </div>
          <div v-else>
            <v-chip color="success" variant="flat" class="mb-1">
              <v-icon start icon="mdi-account-multiple-check"></v-icon>
              <span v-if="biggerScreen" class="fw-semibold pa-1">Dostupno na dva uređaja istovremeno</span>
              <span v-else class="fw-bolder pa-1">Dva uređaja istovremeno</span>
            </v-chip>
          </div>
        </v-card>
        </v-col>
        </v-row>
        <v-row justify="center" v-else>
        <v-col xs="12" sm="12" md="8" lg="8" xl="7" class="text-center">
          <v-card class="mx-auto" elevation="0" color="rgb(0, 0, 0, 0.04)">
            <v-container>
              <v-row dense>
                <v-col cols="12" class="text-center">
                  <h5 class="font-semibold pt-2">Nemaš aktivnih pretplata.</h5>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
        </v-row>
        <v-dialog v-model="this.deleteSubDialog" width="auto">
          <v-card>
            <v-card-title class="mt-2"><span class="font-semibold">Odjavi pretplatu?</span></v-card-title>
            <!-- <v-card-text class="mb-4 mt-2 device-title"><span class="font-semibold">Da li ste sigurni da želite odjaviti ovu pretplatu?</span></v-card-text> -->
            <v-card-text v-if="isPrepaid">
              Klikom na dugme ODJAVI, izvršiće se otkazivanje usluge <br> zaključno sa završetkom trajanja perioda za koji si pretplaćen.
            </v-card-text>
            <v-card-text v-else>
              Odjavu pretplate možeš uraditi u najbližoj poslovnici Supernove. <br>
              Za više informacija pozovi besplatan broj <span class="font-semibold">0800 30 630</span>.
            </v-card-text>
            <v-card-actions class="ma-2">
              <v-spacer></v-spacer>
              <v-btn class="delete-btn" variant="tonal" @click="this.deleteSubDialog = false"><span class="font-semibold">Zatvori</span></v-btn>                         
              <v-btn v-if="isPrepaid" :loading="this.removeSubLoading" class="delete-btn text-white" variant="flat" color="#b10d28" @click="this.removeSubscription()"><span class="text-white font-semibold">Odjavi</span></v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog v-model="this.addSubDialog" width="auto">
          <v-card>
            <v-card-title class="mt-2"><span class="font-semibold">Obnovi pretplatu?</span></v-card-title>
            <!-- <v-card-text class="mb-4 mt-2 device-title"><span class="font-semibold">Da li ste sigurni da želite odjaviti ovu pretplatu?</span></v-card-text> -->
            <v-card-text>
              Obnovom pretplate automatsko obnavljanje usluge će biti uključeno. <br>
              Obnavljanje možeš opet deaktivirati u bilo kojem trenutku do isteka pretplate.
            </v-card-text>
            <v-card-actions class="ma-2">
              <v-spacer></v-spacer>
              <v-btn class="delete-btn" variant="tonal" @click="this.addSubDialog = false"><span class="font-semibold">Otkaži</span></v-btn>                         
              <v-btn :loading="this.addSubLoading" class="delete-btn text-white" variant="flat" color="#b10d28" @click="this.enableSubscription()"><span class="text-white font-semibold">Obnovi</span></v-btn>
            </v-card-actions>
            </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <v-row v-if="isPromo" style="margin-bottom: 60px; margin-top: -20px" justify="space-around">
      <v-col v-if="biggerScreen" md="4" lg="3" xl="2">
        <!-- <h1 class="text-black text-3xl font-semibold"><span class="text-red-700">MOJE</span> PRETPLATE</h1> -->
      </v-col>
      <v-col xs="12" sm="12" md="8" lg="8" xl="7">
        <v-row>
          <v-col>
            <v-card class="mx-auto text-center" elevation="0" color="rgb(0, 0, 0, 0.04)">
              <v-card-text class="fw-semibold" style="font-size: 17px;"><v-icon size="small" class="pr-2">mdi-calendar-sync</v-icon>Postpaid paketi</v-card-text>
              <v-btn @click="openPromoPackages(0)" class="bg-error mb-4 delete-btn">
                <span class="font-bold text-light">Pogledaj ponudu</span>
              </v-btn>
            </v-card>
          </v-col>
          <v-col>
            <v-card class="mx-auto text-center" elevation="0" color="rgb(0, 0, 0, 0.04)">
              <v-card-text class="fw-semibold" style="font-size: 17px;"><v-icon size="small" class="pr-2 pb-1">mdi-credit-card-plus</v-icon>Prepaid paketi</v-card-text>
              <v-btn @click="openPromoPackages(1)" class="bg-error mb-4 delete-btn">
                <span class="font-bold text-light">Pogledaj ponudu</span>
              </v-btn>
            </v-card>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
      <v-divider v-if="isPrepaid || isPromo" class="mt-6"></v-divider>
      <v-row v-if="isPrepaid || isPromo" class="mt-10 pt-2 pb-3 mb-15" justify="space-around">
        <v-col v-if="biggerScreen" md="4" lg="3" xl="2">
          <h1 class="text-black text-3xl font-semibold"><span class="text-red-700">MOJA </span>KARTICA</h1>
        </v-col>
        <v-col xs="12" sm="12" md="8" lg="8" xl="7" class="text-center">
          <v-chip v-if="!biggerScreen" class="mb-8" label text-color="white">
            <v-icon start icon="mdi-credit-card-check"></v-icon>
            <span class="device-title fs-6">Moja kartica</span>
          </v-chip>
          <div v-if="creditCardExists" class="w-100 rounded-lg"> 
            <div class="card-container">
              <header>
                <span class="logo">
                  <h5>{{ this.currentUser.crm_info.Name }}</h5>
                </span>
                <img src="../assets/images/chip.png" alt="Chip" class="chip">
              </header>
              <div class="ccard-details">
                <div class="name-number">
                  <h6>Card Number</h6>
                  <h5 class="number">**** **** **** {{this.currentUser.card_info.last4digits}}</h5>
                </div>
                <div class="valid-date">
                  <h6>Valid Thru</h6>
                  <h5 class="number">{{ formatCardDate }}</h5>
                </div>
              </div>  
              <div class="delete-card text-center">
                <v-btn size="large" class="delete-card-btn bg-error" @click="this.deleteCardDialog = true" density="comfortable" icon="mdi-delete-empty"></v-btn>       
              </div>
            </div>
          </div>
          <div v-else class="w-100 rounded-lg" style="background: rgb(0, 0, 0, 0.04)"> 
            <h5 class="font-semibold pt-4 pb-2 text-black">Nemaš sačuvanu karticu.</h5>
            <p v-if="isPromo" class="promo-card-info">
              Ukoliko želiš da postaneš korisnik nekog od MOVE Prepaid paketa, potrebno je da dodaš karticu za plaćanje, te kupiš paket po želji.
            </p>
            <v-btn @click="this.addCardDialog = true" class="bg-error mb-4 delete-btn">
                <v-icon size="large" class="pr-3 text-light">mdi-credit-card-plus</v-icon><span class="fxont-bold text-light">Dodaj karticu</span>
            </v-btn>
          </div>
          <v-dialog
            v-model="addCardDialog"
            fullscreen
            :scrim="false"
            transition="dialog-bottom-transition"
            eager
          >
            <v-card class="content-center add-card">
              <v-toolbar
                dark
                class="add-card-toolbar"
              >
                <v-toolbar-title v-if="biggerScreen" class="font-bold text-white">DODAVANJE KARTICE</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn variant="text" @click="this.closeCardDialog()">
                    <v-icon size="x-large" class="text-white">mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-container>
                <v-row justify="center">
                  <v-col xs="12" sm="12" md="8" lg="7" xl="5">
                    <div class="text-center">
                      <h1 class="text-2xl mb-5 mt-4">REGISTRACIJA NOVE PLATNE KARTICE</h1>
                    </div>
                    <p v-if="biggerScreen">Neophodno je da koristiš aktivnu platnu karticu koja ima omogućena plaćanja putem Interneta. Postavke kartice za plaćanje putem interneta možeš izvršiti korišćenjem aplikacije mobilnog bankarstva ili kontaktiranjem banke. 
                    <br>Greška prilikom registracije kartice i plaćanja može da se desi ukoliko nije odobreno internet plaćanje karticom, ukoliko na kartici nema dovoljno sredstava, zbog pogrešno unesenih podataka uključujući i šifru koju tvoja banka šalje kao sigurnosnu potvrdu te u slučaju nepredviđenih tehničkih problema.</p>
                    <p v-else style="font-size: 15px;">Neophodno je da koristiš aktivnu platnu karticu koja ima omogućena plaćanja putem Interneta. Postavke kartice za plaćanje putem interneta možeš izvršiti korišćenjem aplikacije mobilnog bankarstva ili kontaktiranjem Vaše banke. 
                    <br>Greška prilikom registracije kartice i plaćanja može da se desi ukoliko nije odobreno internet plaćanje karticom, ukoliko na kartici nema dovoljno sredstava, zbog pogrešno unesenih podataka uključujući i šifru koju tvoja banka šalje kao sigurnosnu potvrdu te u slučaju nepredviđenih tehničkih problema.</p>
                  </v-col>
                </v-row>
                <br>
                <v-row justify="center">
                  <v-col xs="12" sm="8" md="8" lg="7" xl="5">
                    <v-card class="add-card-form">
                      <v-card-text class="text-black">
                        <v-form class="pa-5">
                          <v-text-field
                            v-model="newCard.cardFname"
                            type="text"
                            label="Ime"                           
                            class="pb-2"
                            density="compact"
                            clearable
                            :rules="[rules.required]"
                          ></v-text-field>
                          <v-text-field
                            v-model="newCard.cardLName"
                            type="text"
                            label="Prezime"                      
                            class="pb-2"
                            density="compact"
                            clearable
                            :rules="[rules.required]"
                          ></v-text-field>
                          <v-text-field
                            v-model="newCard.cardStreet"
                            type="text"
                            label="Ulica"
                            class="pb-2"
                            density="compact"
                            clearable
                            :rules="[rules.required]"
                          ></v-text-field>
                          <v-text-field
                            v-model="newCard.cardStreet_nr"
                            type="text"
                            label="Broj"
                            class="pb-2"
                            density="compact"
                            clearable
                            :rules="[rules.required]"
                          ></v-text-field>
                          <v-text-field
                            v-model="newCard.cardCity"
                            type="text"
                            label="Grad"
                            class="pb-2"
                            density="compact"
                            clearable
                            :rules="[rules.required]"
                          ></v-text-field>
                          <v-text-field
                            v-model="newCard.cardZip"
                            type="number"
                            label="Poštanski broj"
                            class="pb-2"
                            density="compact"
                            :rules="[rules.required]"
                          ></v-text-field>
                          <v-select
                            v-model="newCard.cardCountry"
                            :items="this.states"
                            label="Država"
                            class="pb-2"
                            density="compact"
                            clearable
                            :rules="[rules.required]"
                          ></v-select>
                          <v-text-field
                            v-model="newCard.cardEmail"
                            type="email"
                            label="E-mail"
                            placeholder="john.doe@gmail.com"
                            class="pb-2"
                            density="compact"
                            clearable
                            :rules="[rules.required, rules.email]"
                          ></v-text-field>
                          <v-text-field
                            v-model="newCard.cardPhone"
                            type="text"
                            label="Broj telefona"
                            density="compact"
                            clearable
                            :rules="[rules.required]"
                          ></v-text-field>
                          <span style="font-size: 13px; color:rgb(176, 0, 32)">Za potrebe validacije tvoje platne kartice, kreiraće se inicijalna transakcija u iznosu od 0.01KM.</span>
                        </v-form>
                      </v-card-text>
                    <v-card-actions style="font-family: Pero-Bold;">
                      <v-btn
                        variant="flat"
                        @click="this.closeCardDialog()"
                        class="ml-6 mb-5 bg-error"
                        size="large"
                      >
                        <v-icon size="large" class="pr-2 text-light">mdi-close-circle</v-icon>
                        <span class="text-light">Zatvori</span>
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        variant="flat"
                        @click="initializeCardRegistration()"
                        class="mr-5 mb-5 bg-success text-white"
                        size="large"
                        :disabled="isBtnDisabled()"
                        :loading="this.addCardLoading"
                      >
                        <v-icon size="large" class="pr-2 text-light">mdi-arrow-right-circle</v-icon>
                        <span class="text-light">Dalje</span>
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-dialog>
        <v-dialog v-model="this.deleteCardDialog" width="auto">
          <v-card>
            <v-card-title class="mt-2"><span class="font-semibold">Ukloni karticu?</span></v-card-title>
            <v-card-text>Da li sigurno želiš ukloniti kreditnu karticu?</v-card-text>
            <!-- <v-card-text class="mb-4 mt-2 device-title"><span>Da li ste sigurni da želite ukloniti kreditnu karticu?</span></v-card-text> -->
            <v-card-actions class="ma-2">
              <v-spacer></v-spacer>
              <v-btn class="delete-btn" variant="tonal" @click="this.deleteCardDialog = false"><span class="font-semibold">Otkaži</span></v-btn>                         
              <v-btn class="delete-btn text-white" :loading="this.deleteCardLoading" variant="flat" color="#b10d28" @click="this.removeCardByEmail(this.currentUser.ott_email)"><span class="text-white font-semibold">Ukloni</span></v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-col>
    </v-row>
    <!-- ZAKOMENTARISAO ZBOG IZMJENA KOJE MORAJU NA PRODUKCIJU 11.7.2024.-->

    <!-- <v-divider v-if="isPrepaid || isPostpaid || isPromo" class="mt-6"></v-divider>
    <v-row v-if="isPrepaid || isPostpaid || isPromo" class="mt-10 pt-2 mb-15" justify="space-around">
      <v-col v-if="biggerScreen" md="4" lg="3" xl="2">
        <h1 class="text-black text-3xl font-semibold">MOJ <span class="text-red-700">MOVETV+</span>NET</h1>
      </v-col>
      <v-col xs="12" sm="12" md="8" lg="8" xl="7" :class="biggerScreen ? 'text-center center-element' : 'text-center'">
        <v-chip v-if="!biggerScreen" class="mb-8"  label text-color="white">
          <v-icon start icon="mdi-tag"></v-icon>
          <span class="device-title fs-6">Moj MOVETV+NET</span>
        </v-chip>
        <div :class="biggerScreen ? 'w-50 rounded-lg' : 'w-100 rounded-lg'" v-if="activationNumberExists">
          <img v-if="this.currentUser.activation_number[2] == 'MOVE 20G'" src="../assets/images/movetvnet20.png" class="promo-code-img" :style="this.currentUser.activation_number[3] == '3' ? 'filter: grayscale(0)' : 'filter: grayscale(1); opacity: 0.5'" alt="logo" width="280" height="200">
          <img v-else src="../assets/images/movetvnet100.png" class="promo-code-img" :style="this.currentUser.activation_number[3] == '3' ? 'filter: grayscale(0)' : 'filter: grayscale(1); opacity: 0.5'" alt="logo" width="280" height="200">
        </div>
        <div v-else :class="biggerScreen ? 'w-50 rounded-lg' : 'w-100 rounded-lg'" style="background: rgb(0, 0, 0, 0.04)">
          <h5 class="font-semibold pt-4 pb-2 text-black">Nemaš aktivan MOVETV+Mob NET.</h5>
          <v-btn @click="this.addMovetvNetDialog = true" class="bg-error mb-4 delete-btn">
              <v-icon size="large" class="pr-3 text-light">mdi-plus-circle</v-icon><span class="fxont-bold text-light">Dodaj MOVETV+NET</span>
          </v-btn>
        </div>
      </v-col>
    </v-row> -->
    <v-divider v-if="isPrepaid || isPostpaid || isPromo" class="mt-6"></v-divider>
      <v-row v-if="isPrepaid || isPostpaid || isPromo" class="mt-10 pt-2 mb-15" justify="space-around">
        <v-col v-if="biggerScreen" md="4" lg="3" xl="2">
          <h1 class="text-black text-3xl font-semibold">MOJ <span class="text-red-700">PROMO </span>KOD</h1>
        </v-col>
        <v-col xs="12" sm="12" md="8" lg="8" xl="7" :class="biggerScreen ? 'text-center center-element' : 'text-center'">
          <v-chip v-if="!biggerScreen" class="mb-8"  label text-color="white">
            <v-icon start icon="mdi-tag"></v-icon>
            <span class="device-title fs-6">Moj promo kod</span>
          </v-chip>
          <div :class="biggerScreen ? 'w-50 rounded-lg' : 'w-100 rounded-lg'" v-if="promoCodeExists">
            <img v-if="this.currentUser.promo_code[2] == 'prepaid_promo.png'" src="../assets/images/prepaid_promo.png" class="promo-code-img" :style="this.currentUser.promo_code[4] == '3' ? 'filter: grayscale(0)' : 'filter: grayscale(1); opacity: 0.5'" alt="logo" width="280" height="200">
            <img v-else src="../assets/images/postpaid_promo.png" class="promo-code-img" :style="this.currentUser.promo_code[4] == '3' ? 'filter: grayscale(0)' : 'filter: grayscale(1); opacity: 0.5'" alt="logo" width="280" height="200">
          </div>
          <div v-else :class="biggerScreen ? 'w-50 rounded-lg' : 'w-100 rounded-lg'" style="background: rgb(0, 0, 0, 0.04)">
            <h5 class="font-semibold pt-4 pb-2 text-black">Nemaš aktivan promo kod.</h5>
            <v-btn @click="this.addPromoCodeDialog = true" class="bg-error mb-4 delete-btn">
                <v-icon size="large" class="pr-3 text-light">mdi-tag-plus</v-icon><span class="fxont-bold text-light">Dodaj promo kod</span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    <!-- <v-divider v-if="isPrepaid" class="mt-6"></v-divider> -->
    <!-- <v-row v-if="isPrepaid" class="mt-10 mb-10"> -->
      <!-- <v-col v-if="biggerScreen" xs="12" sm="6" md="4" lg="5" xl="4">
        <h1 class="text-black text-3xl font-semibold"><span class="text-red-700">MOJE</span> TRANSAKCIJE</h1>
      </v-col> -->
      <!-- <v-col xs="12" sm="12" md="6" lg="5" xl="4" class="text-center">
        <v-chip v-if="!biggerScreen" class="mb-8"  label text-color="white">
          <v-icon start icon="mdi-account-details"></v-icon>
          <span class="device-title fs-6">Moje transakcije</span>
        </v-chip>
        <v-card v-if="this.currentUser.transactions != null && this.currentUser.transactions.length != 0" class="mx-auto" elevation="0" color="rgb(0, 0, 0, 0.04)">
          <v-container>
            <v-row dense>
              <v-col cols="12" class="text-center">
                <v-btn @click="this.showTransactions = true" variant="tonal" class="bg-error mt-2 mb-2 w-55 delete-btn">
                  <v-icon size="large" class="pr-3 text-light">mdi-eye</v-icon><span class="font-bold text-light">Prikaži transakcije</span>    
                </v-btn>
              </v-col>
            </v-row>
          </v-container> -->
          <!-- <v-dialog v-if="biggerScreen" v-model="this.showTransactions" width="auto" scrollable>
            <v-card>
              <v-card-text>
                <v-table density="compact" style="font-family:'Pero-Bold'">
                  <thead>
                    <tr>
                      <th class="text-left">
                        ID transakcije
                      </th>
                      <th class="text-left">
                        Opis
                      </th>
                      <th class="text-left">
                        Kartica
                      </th>
                      <th class="text-left">
                        Iznos
                      </th>
                      <th class="text-left">
                        Datum
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="item in this.currentUser.transactions" :key="item.transaction_id">
                      <td class="text-left">{{ item.transaction_id }}</td>
                      <td>{{ item.description }}</td>
                      <td>{{ item.cardlastfourdigits }}</td>
                      <td>{{ item.amount }} KM</td> 
                      <td>{{ this.formatDateTransactions(item.transaction_date) }}</td>
                    </tr>
                  </tbody>
                </v-table>
              </v-card-text>
              <v-card-actions>
                <v-btn block @click="this.showTransactions = false" variant="tonal" class="bg-error mt-2 w-55 delete-btn">
                  Zatvori
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-else 
            v-model="this.showTransactions" 
            fullscreen
            :scrim="false"
            transition="dialog-bottom-transition"
            eager
            scrollable
            
            >
            <v-card class="content-center">
              <v-toolbar
                dark
                style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
              >
                <v-toolbar-title class="font-bold text-white">Transakcije</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-toolbar-items>
                  <v-btn variant="text" @click="this.showTransactions = false">
                    <v-icon size="x-large" class="text-white">mdi-close</v-icon>
                  </v-btn>
                </v-toolbar-items>
              </v-toolbar>
              <v-card-text>
                <v-expansion-panels v-for="item in this.currentUser.transactions" :key="item.transaction_id" class="mb-1">
                  <v-expansion-panel style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));" class="text-white" :title="this.formatDateTransactions(item.transaction_date) ">
                      <v-expansion-panel-text class="text-left text-white">
                       ID: {{ item.transaction_id }} <br>
                       Opis: {{ item.description }} <br>
                       Iznos: {{ item.amount }} KM <br>
                       Kartica: {{ item.cardlastfourdigits }}
                    </v-expansion-panel-text>
                  </v-expansion-panel>
                </v-expansion-panels>
              </v-card-text>
            </v-card> 
          </v-dialog> -->
        <!-- </v-card> -->
        <!-- <v-card v-else class="mx-auto" elevation="0" color="rgb(0, 0, 0, 0.04)">
          <v-container>
            <v-row dense>
              <v-col cols="12" class="text-center">
                <h5 class="font-semibold pt-1">Nemaš transakcija.</h5>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </v-col>
    </v-row> -->
    <v-divider class="mt-6"></v-divider>
    <v-row justify="space-around" class="mt-8 pt-2 mb-10">
      <v-col v-if="biggerScreen" md="4" lg="3" xl="2">
        <h1 class="text-black text-3xl font-semibold">MOJI <span class="text-red-700">UREĐAJI</span></h1>
      </v-col>
      <v-col xs="12" sm="12" md="8" lg="8" xl="7" :class="biggerScreen ? 'text-center center-element' : 'text-center'">
        <div class="text-center">
          <v-chip v-if="!biggerScreen" class="mb-8"  label text-color="white">
            <v-icon start icon="mdi-tablet-cellphone"></v-icon>
            <span class="device-title fs-6">Moji uređaji</span>
          </v-chip>
        </div>
        <div :class="biggerScreen ? 'w-50 rounded-lg' : 'w-100 rounded-lg'" v-if="this.currentUser.ott_devices != null && this.currentUser.ott_devices.length != 0">
        <v-card  class="mx-auto" elevation="0" color="rgb(0, 0, 0, 0.04)">
          <!-- <div v-if="!biggerScreen" class="text-center pt-4"><span class="card-details text-red-700 fs-5">Moji uređaji</span></div> -->
          <v-container fluid>
            <v-row dense>
              <v-col
                v-for="item in this.currentUser.ott_devices"
                :key="item.deviceId"
                :cols="this.cols()"
              >
                <v-card color="#e6e6e6" height="160px" class="device ma-1 pt-2 pr-4 pl-4 pb-2">
                  <v-card-title class="mt-1 text-left device-title text-black">{{ item.deviceModel }}</v-card-title>
                  <v-card-text class="mt-1 text-left device-title text-black">{{ item.deviceName }}</v-card-text>
                  <!-- <v-card-text class="device-subtitle">{{ deviceStatus(item.deviceStatus) }} ( {{ item.distributionType }} ) </v-card-text> -->
                  <v-btn v-if="biggerScreen" size="small" :disabled="item.deletable == 'no'" block class="bg-error mt-3 delete-device-btn" @click="this.openDeleteDeviceDialog(item.deviceId)"><span class="fw-semibold">Obriši</span></v-btn>
                  <v-btn v-else :disabled="item.deletable == 'no'" block class="bg-error mt-3 delete-device-btn" @click="this.deleteDeviceDialog = true"><span class="fw-semibold">Obriši</span></v-btn>
                  <v-dialog v-model="this.deleteDeviceDialog" width="auto">
                    <v-card>
                      <v-card-text class="mb-4 mt-2 device-title"><span class="font-semibold">Da li sigurno želiš obrisati ovaj uređaj?</span></v-card-text>
                      <v-card-actions class="ma-2">
                        <v-spacer></v-spacer>
                        <v-btn class="delete-btn" variant="tonal" @click="this.deleteDeviceDialog = false"><span class="font-semibold">Otkaži</span></v-btn>                         
                        <v-btn class="delete-btn text-white" :loading="this.loading" variant="flat" color="#b10d28" @click="this.deleteDevice(this.deviceIdToRemove)"><span class="text-white font-semibold">Obriši</span></v-btn>
                      </v-card-actions>
                    </v-card>
                  </v-dialog>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
        </div>
        <div v-else :class="biggerScreen ? 'w-50 rounded-lg' : 'w-100 rounded-lg'"> 
        <v-card class="mx-auto" elevation="0" color="rgb(0, 0, 0, 0.04)">
          <v-container>
            <v-row dense>
              <v-col cols="12" class="text-center">
                <h5 class="font-semibold pt-1 text-black">Nemaš sačuvanih uređaja.</h5>
              </v-col>
            </v-row>
          </v-container>
        </v-card>
      </div>
      </v-col>
    </v-row>
    <!-- <v-divider class="mt-6"></v-divider> -->
    <!-- <v-row class="mt-12 mb-10">
      <v-col v-if="biggerScreen" md="4" lg="5" xl="4">
        <h1 class="text-black text-3xl font-semibold">PODEŠAVANJA</h1>
      </v-col>
      <v-col xs="12" sm="12" md="6" lg="5" xl="4" class="text-center">
        <router-link class="no-underline" :to="{name: 'ChangePassword'}">
          <v-btn class="bg-error mt-2 w-55 delete-btn">
            <v-icon size="large" class="pr-3 text-light">mdi-lock-reset</v-icon><span class="font-bold text-light">Promijeni lozinku</span>    
          </v-btn>
        </router-link>
      </v-col>
    </v-row> -->

    <!--------------------- SVI DIJALOZI ------------------------>
    <v-dialog v-model="this.logOutDialog" width="auto">
      <v-card>
        <v-card-title class="mt-2"><span class="font-semibold">Odjavi se?</span></v-card-title>
        <v-card-text>Da li sigurno želiš da se odjaviš?</v-card-text>
        
        <v-card-actions class="ma-2">
          <v-spacer></v-spacer>
          <v-btn class="delete-btn" variant="tonal" @click="this.logOutDialog = false"><span class="font-semibold">Otkaži</span></v-btn>                         
          <v-btn class="delete-btn" :loading="this.loading" variant="flat" color="#b10d28" @click="this.logOut"><span class="text-white font-semibold">Odjavi se</span></v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="cardDialog"
      fullscreen
      :scrim="true"
      transition="dialog-bottom-transition"
      eager
      class="add-card-dialog"
    >
      <v-card class="content-center add-card">
        <v-toolbar
          dark
          style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(145, 136, 137), rgb(245, 130, 32));"
        >
          <v-toolbar-title v-if="biggerScreen" class="font-bold text-white">DODAVANJE KARTICE</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
            <v-btn variant="text" @click="closeDialog()">
              <v-icon size="x-large" class="text-white">mdi-close</v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
        <v-container>
          <v-row justify="center">
            <v-col xs="12" sm="12" md="10" lg="8" xl="6">
              <div class="text-center">
                <h1 class="text-2xl font-bold mb-5 mt-4">REGISTRACIJA NOVE PLATNE KARTICE</h1>
              </div>
              <p class="mb-3 text-sm">Da bi aktivirali MOVE TV uslugu, potrebno je da registruješ i sačuvaš platnu karticu koja će biti korištena za naplatu. Prilikom registracije biće rezervisano 0,10KM kako bi se provjerila validnost platne kartice. Ovaj iznos će ti biti vraćen na račun u roku od 30 dana, u zavisnosti od politika i pravila Vaše banke.</p>
              <p class="text-sm">Neophodno je koristiti aktivnu platnu karticu koja ima omogućena plaćanja putem Interneta. Postavke kartice za plaćanje putem interneta možeš izvršiti korišćenjem aplikacije mobilnog bankarstva ili kontaktiranjem banke. Greška prilikom registracije kartice i plaćanja može da se desi ukoliko nije odobreno internet plaćanje karticom, 
                ukoliko na kartici nema dovoljno sredstava, zbog pogrešno unesenih podataka uključujući i šifru koju tvoja banka šalje kao sigurnosnu potvrdu te u slučaju nepredviđenih tehničkih problema.</p>
            </v-col>
          </v-row>
          <br>
            <v-row justify="center">
              <v-col xs="12" sm="12" md="10" lg="8" xl="6">
                <v-card>
                    <iframe 
                      :src="this.bankUrl" 
                      width="100%" 
                      height="800px" 
                      style="border:0"
                      >                      
                    </iframe>
                </v-card>
              </v-col>
            </v-row>
          </v-container>
      </v-card>
    </v-dialog>
    <v-dialog v-model="this.addPromoCodeDialog" :width="biggerScreen ? '450px' : '100%'" transition="dialog-top-transition">
        <v-card>
          <v-card-title class="mt-2"><span class="pl-2 font-semibold">Dodaj promo kod</span></v-card-title>
          <!-- <v-card-subtitle>Unesi važeći promo kod i ostvari popust na određene usluge.</v-card-subtitle> -->
          <v-text-field
            v-model="this.promoCode"
            variant="outlined"
            clearable
            maxlength="8"
            label="Unesi promo kod"
            style="padding: 10px 23px 0px; margin-top:6px;"
            prepend-inner-icon="mdi-tag-plus"
            :rules="[rules.codelength, rules.codeformat]"
            @keyup.enter="addPromoCode()"
          ></v-text-field>
          <v-card-actions class="mb-1 mr-3">
            <v-spacer></v-spacer>
            <v-btn class="delete-btn" variant="tonal" @click="this.addPromoCodeDialog = false; this.promoCode = null"><span class="font-semibold">Otkaži</span></v-btn>                         
            <v-btn class="delete-btn text-white" :loading="this.addCodeBtnLoading" :disabled="this.isAddPromoBtnDisabled" variant="flat" color="#b10d28" @click="addPromoCode()"><span class="text-white font-semibold">Dodaj</span></v-btn>
          </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="this.addMovetvNetDialog" :width="biggerScreen ? '450px' : '100%'" transition="dialog-top-transition">
        <v-card>
          <v-card-title class="mt-2"><span class="pl-2 font-semibold">Dodaj MOVETV+NET</span></v-card-title>
          <!-- <v-card-subtitle>Unesi važeći promo kod i ostvari popust na određene usluge.</v-card-subtitle> -->
          <v-text-field
            v-model="this.activationNumber"
            variant="outlined"
            maxlength="19"
            label="Unesi aktivacioni broj"
            style="padding: 10px 23px 0px; margin-top:6px;"
            placeholder="XXXX-XXXX-XXXX-XXXX"
            @input="formatActivationNumber"
            :rules="[rules.luhn_number]"
            @keyup.enter="addActivationNumber()"
          ></v-text-field>
          <v-card-actions class="mb-1 mr-3">
            <v-spacer></v-spacer>
            <v-btn class="delete-btn" variant="tonal" @click="this.addMovetvNetDialog = false; this.activationNumber = ''"><span class="font-semibold">Otkaži</span></v-btn>                         
            <v-btn class="delete-btn text-white" :loading="this.addActivationNumBtnLoading" :disabled="this.isAddActivationBtnDisabled" variant="flat" color="#b10d28" @click="addActivationNumber()"><span class="text-white font-semibold">Dodaj</span></v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
        <v-dialog v-if="biggerScreen" v-model="this.showTransactions" width="auto" scrollable>
          <v-card>
            <v-card-text>
              <v-table density="compact" style="font-family:'Pero-Bold'">
                <thead>
                  <tr>
                    <th class="text-left">
                      ID transakcije
                    </th>
                    <th class="text-left">
                      Opis
                    </th>
                    <th class="text-left">
                      Kartica
                    </th>
                    <th class="text-left">
                      Iznos
                    </th>
                    <th class="text-left">
                      Datum
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="item in this.currentUser.transactions" :key="item.transaction_id">
                    <td class="text-left">{{ item.transaction_id }}</td>
                    <td>{{ item.description }}</td>
                    <td>{{ item.cardlastfourdigits }}</td>
                    <td>{{ item.amount }} KM</td> 
                    <td>{{ this.formatDateTransactions(item.transaction_date) }}</td>
                  </tr>
                </tbody>
              </v-table>
            </v-card-text>
            <v-card-actions>
              <v-btn block @click="this.showTransactions = false" variant="tonal" class="bg-error mt-2 w-55 delete-btn">
                Zatvori
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-else 
          v-model="this.showTransactions" 
          fullscreen
          :scrim="false"
          transition="dialog-bottom-transition"
          eager
          scrollable    
          >
          <v-card class="content-center">
            <v-toolbar
              dark
              style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));"
            >
              <v-toolbar-title class="font-bold text-white">Transakcije</v-toolbar-title>
              <v-spacer></v-spacer>
              <v-toolbar-items>
                <v-btn variant="text" @click="this.showTransactions = false">
                  <v-icon size="x-large" class="text-white">mdi-close</v-icon>
                </v-btn>
              </v-toolbar-items>
            </v-toolbar>
            <v-card-text>
              <v-expansion-panels v-for="item in this.currentUser.transactions" :key="item.transaction_id" class="mb-1">
                <v-expansion-panel style="background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));" class="text-white" :title="this.formatDateTransactions(item.transaction_date) ">
                    <v-expansion-panel-text class="text-left text-white">
                      ID: {{ item.transaction_id }} <br>
                      Opis: {{ item.description }} <br>
                      Iznos: {{ item.amount }} KM <br>
                      Kartica: {{ item.cardlastfourdigits }}
                  </v-expansion-panel-text>
                </v-expansion-panel>
              </v-expansion-panels>
            </v-card-text>
          </v-card> 
        </v-dialog>
  </v-container>
</div>
</template>

<script>
import {ref, onBeforeMount, onUpdated ,computed} from 'vue';
import moment from 'moment';
import {useUserStore} from '../stores/UserStore';
import { getCurrentInstance } from 'vue';
import { useRouter } from 'vue-router'
import { onMounted } from 'vue';

export default {

    setup(){
      
      const userStore = useUserStore();
      const router = useRouter();
      
      let currentUserString = localStorage.getItem('user');
      let currentUser = ref(JSON.parse(currentUserString));
      let currentUserPackagesString = localStorage.getItem('packages');
      let currentUserPackages = ref(JSON.parse(currentUserPackagesString));

      // let currentUserTypeString = localStorage.getItem('user_type');
      // let currentUserType = ref(userStore.currentUserType);
   
      let dialog = ref(false);
      let windowSubs = ref(0);
      let length = ref(3);
      let enableRenewPackageSuccess = ref(false);
      let disableRenewPackageSuccess = ref(false);
      let deleteDeviceDialog = ref(false);
      let deleteDeviceError = ref(false);
      let deleteCardDialog = ref(false);
      let deviceIdToRemove = ref(null);
      let addCardDialog = ref(false);
      let logOutDialog = ref(false);
      let loading = ref(false);
      let addCardLoading = ref(false);
      let deleteCardLoading = ref(false);
      let removeCardError = ref(false);
      let cardDialog = ref(false);
      let deleteSubDialog = ref(false);
      let removeSubLoading = ref(false);
      let addSubLoading = ref(false);
      let enableSubDialog = ref(false);
      let currentAddSubId = ref(null);
      let addSubDialog = ref(false);
      let showNoTransactionsMessage = ref(false);
      const currentSubId = ref(null);
      let showTransactions = ref(false);
      let states = ref(['Bosna i Hercegovina']);
      let newCard = ref({
        cardFname: null,
        cardLName: null,
        cardStreet: null,
        cardStreet_nr: null,
        cardCity: null,
        cardZip: null,
        cardCountry: null,
        cardEmail: null,
        cardPhone: null
      });
      let rules = ref({
        required: value => !!value || "Obavezno polje.",
        email: value => {
          const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          return pattern.test(value) || 'Neispravna e-mail adresa.'
        },
        codelength: value => !!value && value.length == 8 || "Potrebno unijeti 8 karaktera.",
        codeformat: value => {
          const regex = /^[A-Z0-9]+$/;
          return regex.test(value) || "Neispravan format koda."
        },
        luhn_number: value => validateLuhn() || "Aktivacioni broj nije validan."
      });
      let cardInitError = ref(false);
      let bankUrl = ref('');
      let statusNotification = ref(false);
      let statusNotificationMessage = ref('');
      let statusDescMessage = ref('');
      let statusColor = ref(null);
      let addPromoCodeDialog = ref(false);
      let addMovetvNetDialog = ref(false);
      let promoCode = ref(null);
      let activationNumber = ref('');
      let addCodeBtnLoading = ref(false);
      let addActivationNumBtnLoading = ref(false);
      let addPromoCodeError = ref(false);
      let addPromoCodeSuccess = ref(false);
      let promoCodePicType = ref(null);

      onBeforeMount(() => {
          if(localStorage.getItem('user') && localStorage.getItem('packages')){
            window.scrollTo(0, 0);
            getUserOnPageLoad();  
          }else{
            localStorage.removeItem('user');
            localStorage.removeItem('packages');
            router.push({ name: 'SignInView'});
          }
      });

      function formatDate(date){
        let datum = new Date(date);
        moment.locale('sr');
        return moment(datum).format('ll');
      };

      function formatDateTransactions(date){
        let datum = new Date(date);
        moment.locale('sr');
        return moment(datum).format("DD.MM.YYYY HH:mm");
      }

      function cols(){
        if(this.$vuetify.display.width >= 1280){
          return 6;
        }else{
          return 12;
        }
      }

      function deviceStatus(status){
        if(status = "Enabled"){
          return 'Aktivan';
        }else{
          return 'Neaktivan';
        }
      }

      function getCurrentUserAdress() {
        return this.currentUser.crm_info.Street + ' ' + this.currentUser.crm_info.Street_nr + ' ' + this.currentUser.crm_info.City;
      }

      function openDeleteDeviceDialog(device_id){
        this.deleteDeviceDialog = true;
        this.deviceIdToRemove = device_id;
      }

      async function deleteDevice(device_id){

        this.loading = true;
        let response = await this.userStore.deleteDeviceById(device_id, this.currentUser.ott_email);

        if(response != null && response.result[0].result_code == 0){
          this.currentUser = JSON.parse(localStorage.getItem('user'));
          this.loading = false;
          this.deleteDeviceDialog = false;
        }else{
          // this.logInFail = true;
          this.deleteDeviceDialog = false;
          this.deleteDeviceError = true;
          this.loading = false;
        }
      }

      async function removeCardByEmail(email){
        this.deleteCardLoading = true;
        let response = await this.userStore.removeCardByEmail(email);

        if(response.result[0].result_code == 0){
          this.currentUser = JSON.parse(localStorage.getItem('user'));
          this.deleteCardLoading = false;
          this.deleteCardDialog = false;
        }else{
          // this.logInFail = true;
          this.deleteCardDialog = false;
          this.deleteCardLoading = false;
          this.removeCardError = true;
        }
      }

      function closeDialog(){
        this.cardDialog = false;
      }

      function logOut(){
        userStore.logOutUser(false);
        try{
          localStorage.removeItem('user');
          localStorage.removeItem('packages');
          localStorage.removeItem('hardwares');
          localStorage.removeItem('timestamp');
          router.push({ name: 'HomeView' });
        } catch (error){
          console.log('Error removing items:', error);
        }
        
      }

      function validateLuhn() {

        if(activationNumber == null || activationNumber == '' || activationNumber.value.length < 19){
          return false;
        }else if(activationNumber.value.length == 19){

          let number = activationNumber.value.replace(/-/g, '');
          
          let arr = (number + '')
            .split('')
            .reverse()
            .map(x => parseInt(x));
          let lastDigit = arr.splice(0, 1)[0];
          let sum = arr.reduce(
            (acc, val, i) =>
              i % 2 !== 0 ? acc + val : acc + ((val *= 2) > 9 ? val - 9 : val),
            0
          );
          sum += lastDigit;
          return sum % 10 === 0;
        }else{
          return true;
        }
      }

      function formatActivationNumber(){
          activationNumber.value = activationNumber.value
          //.replace(/\s+/g, '')
          //.replace(/(\d{4})/g, '$1 ')
          .replace(/-/g, '')
          .replace(/(\d{4})(?=\d)/g, '$1-')
          .trim();
        }

      async function initializeCardRegistration(){

        this.addCardLoading = true;
        let response = await this.userStore.initCardRegistration(this.currentUser.ott_email, this.newCard);

        // console.log(response);

        if(response.result[0].result_code == 0){
          this.bankUrl = response.result_objects.fullUrl;
          // this.cardDialog = true;
          window.open(this.bankUrl, "_self");
          this.addCardLoading = false;
        }else{
          this.addCardDialog = false;
          this.addCardLoading = false;
          this.cardInitError = true;
        }
      }

      async function getUserOnPageLoad(){
        let user = JSON.parse(localStorage.getItem('user'));
        let user_email = user.ott_email;

        const userStore = useUserStore();
  
        let updatedUser = await userStore.loadUserByEmail(user_email);
        await userStore.getSubActivePackages(user_email);
        
        localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(updatedUser));

        // localStorage.removeItem('packages');
        // localStorage.setItem('packages', JSON.stringify(updatedUserPackages));

      };

      
      function isBtnDisabled(){
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        let validEmail = pattern.test(this.newCard.cardEmail);

        if(this.newCard.cardFname == '' || this.newCard.cardLName == '' || this.newCard.cardCity == '' || this.newCard.cardCountry == '' || 
          this.newCard.cardStreet == '' || this.newCard.cardStreet_nr == '' || this.newCard.cardPhone == '' || this.newCard.cardZip == '' || this.newCard.cardEmail == '' || validEmail == false){
          return true;
        }else{
          return false;
        }
      };

      function closeCardDialog(){
        this.addCardLoading = false;
        this.addCardDialog = false;
        this.newCard.cardFname = null;
        this.newCard.cardLName = null;
        this.newCard.cardStreet = null;
        this.newCard.cardStreet_nr = null;
        this.newCard.cardCity = null;
        this.newCard.cardZip = null;
        this.newCard.cardCountry = null;
        this.newCard.cardEmail = null;
        this.newCard.cardPhone = null;
      };

      function openRemoveSubDialog(packageId){
        this.deleteSubDialog = true;
        this.currentSubId = packageId;
      }

      function openEnableSubDialog(packageId){
        this.currentAddSubId = packageId;
        this.addSubDialog = true;
      }

      function openTransactionsDialog(){
        if(this.currentUser.transactions.length > 0){
          this.openTransactionsDialog = true
        }
        this.showNoTransactionsMessage = true;
      }

      async function removeSubscription(){
        
        this.removeSubLoading = true;
        let response = await this.userStore.removeSubscriptionById(this.currentSubId, this.currentUser.ott_email);

        if(response.result[0].result_code == 0){
          this.currentUser = JSON.parse(localStorage.getItem('user'));
          this.currentUserPackages = JSON.parse(localStorage.getItem('packages'));
          this.removeSubLoading = false;
          this.deleteSubDialog = false;
          this.disableRenewPackageSuccess = true;
        }else{
          // this.logInFail = true;
          this.deleteSubDialog = false;
          this.removeSubLoading = false;
        }
      };

      async function enableSubscription(){
        
        this.addSubLoading = true;
        let response = await this.userStore.enableRecurringProcess(this.currentAddSubId, this.currentUser.ott_email);

        if(response.result[0].result_code == 0){
          this.currentUser = JSON.parse(localStorage.getItem('user'));
          this.currentUserPackages = JSON.parse(localStorage.getItem('packages'));
          this.addSubLoading = false;
          this.addSubDialog = false;
          this.enableRenewPackageSuccess = true;
        }else{
          // this.logInFail = true;
          this.addSubDialog = false;
          this.addSubLoading = false;
        }
      };

      async function addPromoCode(){
        
        this.addCodeBtnLoading = true;
        let response = await this.userStore.addPromoCode(this.currentUser.ott_email, this.promoCode);

        if(response != null && response.result[0].result_code == 0){
          this.currentUser = JSON.parse(localStorage.getItem('user'));
          this.addCodeBtnLoading = false;
          this.addPromoCodeSuccess = true;
          this.addPromoCodeDialog = false;
        }else{
          this.addCodeBtnLoading = false;
          this.addPromoCodeError = true;
        }
      }

      async function addActivationNumber(){
        console.log("TU SAM");
      }

      function openPromoPackages(user_type){
        localStorage.setItem('user_type', user_type);
        router.push({ name: 'PackagesView'});
      }

      return {currentUser, currentUserPackages, userStore, dialog, windowSubs, length, deleteDeviceDialog, deviceIdToRemove, activationNumber, promoCodePicType, enableRenewPackageSuccess, addCodeBtnLoading, disableRenewPackageSuccess, deleteDeviceError, deleteCardDialog, addCardDialog, addSubDialog, currentSubId, currentAddSubId, states, loading, deleteCardLoading, showTransactions, promoCode, addPromoCodeSuccess, addPromoCodeError, addCardLoading, cardInitError, removeCardError, removeSubLoading, addSubLoading, newCard, cardDialog, deleteSubDialog, addPromoCodeDialog, addActivationNumBtnLoading, addMovetvNetDialog, rules, bankUrl, statusNotification, statusNotificationMessage, statusDescMessage, getCurrentUserAdress, formatDate, formatDateTransactions, cols, deviceStatus, getUserOnPageLoad, validateLuhn, openDeleteDeviceDialog, deleteDevice, initializeCardRegistration, logOut, logOutDialog, closeDialog, removeCardByEmail, isBtnDisabled, closeCardDialog, removeSubscription, openEnableSubDialog, enableSubscription, openRemoveSubDialog, openTransactionsDialog, openPromoPackages, addPromoCode, addActivationNumber, formatActivationNumber}
    },

    computed:{
      biggerScreen () {
        if(this.$vuetify.display.width >= 960){
          return true;
        }else{
          return false;
        }
      },

      textCenter(){
        if(this.$vuetify.display.width >= 960){
          return '';
        }else{
          return 'text-center';
        }
      },

      isDisabled(){
      const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      let validEmail = pattern.test(this.newCard.cardEmail);

      if(this.newCard.cardFname == '' || this.newCard.cardLName == '' || this.newCard.cardCity == '' || this.newCard.cardCountry == '' || 
         this.newCard.cardStreet == '' || this.newCard.cardStreet_nr == '' || this.newCard.cardStreet_nr == '' || this.newCard.cardEmail == '' || validEmail == false){
        return true;
      }else{
        return false;
      }
    },

    formatCardDate(){
      let exp_date = this.currentUser.card_info.expiration.slice(2);
      let year = exp_date.slice(0, 2);
      let day = exp_date.slice(-2);
      
      return day + '/' + year;
    },

    creditCardExists(){
      let card_info = JSON.parse(JSON.stringify(this.currentUser.card_info));
      if(Object.keys(card_info).length == 0){
        return false;
      }else{
        return true;
      }
    },

    promoCodeExists(){
      
      if(this.currentUser.promo_code == "NO_DATA"){
        return false;
      }else{
        let promo_code = JSON.parse(JSON.stringify(this.currentUser.promo_code));
        if(Object.keys(promo_code).length == 0){
          return false;
        }else{
          return true;
        }
      }
    },

    activationNumberExists(){
      
      if(this.currentUser.activation_number == "NO_DATA"){
        return false;
      }else{
        let activation_num = JSON.parse(JSON.stringify(this.currentUser.activation_number));
        if(Object.keys(activation_num).length == 0){
          return false;
        }else{
          return true;
        }
      }
    },

    getPromoCodeCard(){

      let promo_code_pic_url = '../assets/images/' + this.currentUser.promo_code[2];

      console.log(promo_code_pic_url);

      return promo_code_pic_url;

    },

    isPrepaid(){
      if(this.currentUser.crm_info.VcceState != null && this.currentUser.crm_info.VcceState.substring(0, 3) == 'PRE'){
        return true;
      }else{
        return false;
      }
    },

    isPostpaid(){
      if(this.currentUser.crm_info.VcceState != null && this.currentUser.crm_info.VcceState.substring(0, 3) == 'POS'){
        return true;
      }else{
        return false;
      }
    },

    isPromo(){
      if(this.currentUser.crm_info.VcceState != null && this.currentUser.crm_info.VcceState.substring(0, 3) == 'UNI'){
        return true;
      }else{
        return false;
      }
    },

    isInVcce(){
      if(this.currentUser.crm_info.VcceState != null){
        return true;
      }else{
        return false;
      }
    },

    filteredPackages(){
      return this.currentUserPackages.filter(item => item.isPackageActive == '1');
    },

    isAddPromoBtnDisabled(){

      const regex = /^[A-Z0-9]+$/;
      if(this.promoCode == null){
        return true;
      }
      if(regex.test(this.promoCode) && this.promoCode.length == 8){
        return false;
      }else{
        return true;
      }
    },

    isAddActivationBtnDisabled(){
      if(this.activationNumber == null || this.activationNumber == ""){
        return true;
      }else{
        if(this.activationNumber.length == 19){
          let number = this.activationNumber.replace(/-/g, '');
          
          let arr = (number + '')
            .split('')
            .reverse()
            .map(x => parseInt(x));
          let lastDigit = arr.splice(0, 1)[0];
          let sum = arr.reduce(
            (acc, val, i) =>
              i % 2 !== 0 ? acc + val : acc + ((val *= 2) > 9 ? val - 9 : val),
            0
          );
          sum += lastDigit;

          if(sum % 10 === 0){
            return false;
          }else{
            return true;
          }
        }else{
          return true;
        }
      }
    }

  },

  created(){
    
    if(this.currentUser && this.currentUser.crm_info.VcceState != null && this.currentUser.crm_info.VcceState == 'UNI-Initial'){
      this.statusColor = 'warning';
      this.statusNotificationMessage = "Aktivacija promo paketa u toku...";
      this.statusDescMessage = "Preuzmite aplikaciju i iskoristite promo period."
      this.statusNotification = true;
    }

  },

}
</script>

<style scoped>
    .profile{
        /* height: 850px; */
        padding-top: 50px;
        /* background: rgb(0, 0, 0, 0.1); */
        background-color: rgb(233, 233, 233);
        /* overflow-y: scroll; */
        /* scrollbar-width: none; 
        -ms-overflow-style: none; */
    }

    .profile h1{
      font-family: 'Pero-Bold';
    }

    .profile h5{
      font-family: 'Pero-Bold';
    }

    .profile::-webkit-scrollbar { /* WebKit */
        width: 0;
        height: 0;
    }

    .dialog{
        position: absolute;
        right: 0px;
    }

    .acc-details{
      font-family: 'Roboto-Light';
      font-size: 14px;
      font-weight: 600;
    } 

    .card-details{
      font-family: 'Roboto-Light';
      font-size: 17px;
      font-weight: 800;
    }
    
    .device{
      /* background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32)); */
      background-color: gray;
    }

    .device-title{
      font-family: 'Pero-Bold';
    }
    .device-subtitle{
      font-family: 'Roboto-Light';
      font-size: 15px;
      font-weight: 600;
    }

    .delete-device-btn{
      font-family: 'Pero-Bold';
    }

    .delete-btn{
      font-family: 'Pero-Bold';
    }

    .add-card{
      /* background: rgb(0, 0, 0, 0.88);; */
      background-color: #CCCCCC;
    }

    /* Card Style */
    .card-container{
      position: relative;
      /* background-color: rgb(0, 0, 0); */
      background-color: rgba(255, 255, 255, 0.6);
      backdrop-filter: blur(40px);
      padding: 21px;
      border-radius: 16px;
      max-width: 280px;
      width: 100%;
      height: 173px;
      box-shadow: 7px 8px 10px rgba(0, 0, 0, 0.15);
      border: 0.2px solid rgba(255, 255, 255, 0.2);
      margin: 0 auto;
      /* border-color: rgb(122, 122, 122); */
      /* border-width: 2px; */
    }

    /* Promo Code Style */
    .promo-code-img{
      position: relative;
      border-radius: 16px;
      box-shadow: 7px 8px 10px rgba(0, 0, 0, 0.15);
      margin: 0 auto;
    }

    .name-number{
      text-align: left;
    }

    header,
    .logo{
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    .logo img{
      width: 55px;
      margin-right: 10px;
    }

    .logo h5{
      font-size: 14px;
    }

    h5{
      font-size: 16px;
      font-weight: 400;
      color: #000000;
    }
    header .chip{
      width: 33px;  
    }
    h6{
      color: #000000;
      font-size: 10px;
      font-weight: 400;
    }

    h5.number{
      margin-top: 4px;
      font-size: 16px;
      letter-spacing: 1px;
    }

    h5.name{
      margin-top: 0px;
    }

    .card-container .ccard-details{
      margin-top: 40px;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .delete-card{
      padding-top: 20px;
    }
    .delete-card-btn{
      /* border: 3px solid rgb(216, 216, 216); */
      /* border-color:rgb(109, 0, 0); */
      box-shadow: 5px 8px 10px rgba(0, 0, 0, 0.12);
    }

    .add-card{
      /* background-color: #dcdcdc;   */
      background-color: #b10d28;
    }
    
    .add-card h1{
      font-family: 'Pero-Bold';
      color: white;
      font-size: 26px;
      padding-bottom: 15px;
      padding-top: 14px;
    }

    .add-card p{
      font-family: 'Pero-Light';
      font-size: 17px;
      color: white;
      font-weight: 600;
    }

    .add-card-form{
      background-color: #e0e0e0;
      font-family: 'Pero-Bold';
      color: gray;
    }

    .add-card-toolbar{
      background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));
      font-family: 'Pero-Bold';
    }

    .message{
      padding-bottom: 10px;
      font-family: 'Roboto-Bold';
      font-size: 16px;
    }  

    .profile-section{ 
      /* background-color: #c9c9c9; */
      background: rgb(0, 0, 0, 0.04);                                            
      padding: 30px;
      border-radius: 8px;
      margin-bottom: 40px;
      color: white;
      margin-bottom: 30px;
      align-content: center;
      
    }

    @media only screen and (max-width: 960px){
    .profile-section{
      margin-right: 15px;
      margin-left: 15px;
    }
  }

  .promo-card-info{
    padding:10px; 
    font-size: 14px; 
    margin-left: 25px; 
    margin-right: 25px; 
    background-color: #e4e2e2; 
    border-radius: 6px; 
    box-shadow: 4px 4px 8px 1px rgba(0,0,0,0.1);
    margin-bottom: 15px;
    margin-top: 7px;
  }

  .center-element{
    display: flex;
    justify-content: center;
  }

</style>