<template>
<div class="loader" v-if="this.isLoading">
  <div class="loader-spinner"></div>
</div>
<div class="cardSuccess">
  <v-container>
    <v-row class="text-center">
      <v-col cols="12">
        <v-card v-if="this.isLoading == false" color="transparent" flat class="mt-10">
          <div class="display-6 mt-10 title">
            <span>Uspješno dodana kartica.</span>
            <img class="mx-auto mt-8" src="../assets/images/card-success.svg" alt="card-success"/>
          </div>
          <div class="mt-2">
            <router-link class="no-underline" :to="{name: 'ProfileView'}">
              <v-btn color="black" size="x-large" variant="flat" rounded="2" class="mt-8"><v-icon class="pr-2" color="white" size="x-large">mdi-account-arrow-left</v-icon><span class="font-bold text-white pl-1">Moj nalog</span></v-btn>
            </router-link>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</div>
</template>

<script>
import {ref, onBeforeMount, onMounted ,computed} from 'vue';
import {useUserStore} from '../stores/UserStore';

export default{
    setup(){

      let isLoading = ref(true);

      onMounted(() => {
          if(localStorage.getItem('user')){
              getUserOnPageLoad();
          }

      });

      async function getUserOnPageLoad(){

        let user = JSON.parse(localStorage.getItem('user'));
        let user_email = user.ott_email;

        const userStore = useUserStore();
        
        let updatedUser = await userStore.loadUserByEmailCardSuccess(user_email);
        localStorage.removeItem('user');
        localStorage.setItem('user', JSON.stringify(updatedUser));

        isLoading.value = false;
      };

      return{isLoading, getUserOnPageLoad}
    }
}

</script>

<style>

.cardSuccess{
  /* font-family: 'Pero-Bold'; */
  width: 100%;
  font-size: 14px;
  height: 720px;
  padding-top: 50px;
  color: white;
  background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));
}

.title{
    font-family: 'Pero-Bold';
}

.details{
  font-family: 'Pero-Light';
  font-size: 22px;
}
.details span{
    font-family: 'Pero-Bold';
}

.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999; /* Ensure it's above other elements */
}

.loader-spinner {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid #971818;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

</style>