<template>
    <div class="notFound">
      <v-container>
        <v-row class="text-center">
          <v-col>
            <v-card color="transparent" flat class="mt-10">
              <div class="display-5 mt-10 title">Stranica nije pronađena.</div>
              <div class="mt-3 details">
                Stranica ne postoji, provjeri unos ili se klikom vrati na početnu.
                <!-- <router-link class="no-underline" :to="{name: 'HomeView'}">
                  <a class="page-links"><span class="text-lg font-bold">početnu.</span></a>
                </router-link> -->
              </div>
              <div>
                <router-link class="no-underline" :to="{name: 'HomeView'}">
                  <v-btn color="black" size="large" variant="flat" rounded="2" class="mt-8"><v-icon class="pr-2" color="white" size="x-large">mdi-arrow-left-circle</v-icon><span class="font-bold text-white pl-1">Početna</span></v-btn>
                </router-link></div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>

<script>
export default{
    
}

</script>

<style>

  .notFound{
    font-size: 14px;
    height: 600px;
    color: white;
    background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));
  }

  .details{
    font-family: 'Pero-Light';
    font-size: 22px;
  }
  .details span{
      font-family: 'Pero-Bold';
  }

  .title{
      font-family: 'Pero-Bold';
  }

</style>