<template>
  <div v-if="biggerScreenCarousel" class="hero">
    <v-carousel
      show-arrows="hover"
      :height="carouselHeight"
      cycle
      color="white"
      hide-delimiter-background
      interval="3500"
      hide-delimiters
    >
      <v-carousel-item
        v-for="(item,i) in items"
        :key="i"
        :src="item.src"
        cover
        @click="handleItemClick(i)"
      ></v-carousel-item>
    </v-carousel>
  </div>
  <div class="hero" v-else>
    <v-carousel
      :show-arrows="false"
      :height="carouselHeight"
      cycle
      color="white"
      hide-delimiter-background
      interval="3500"
      hide-delimiters
    >
      <v-carousel-item
        v-for="(item,i) in mobile_items"
        :key="i"
        :src="item.src"
        cover
        @click="handleItemClick(i)"
      ></v-carousel-item>
    </v-carousel>
  </div>
  <div class="about" :style="this.$vuetify.display.width >= 960 ? 'height:700px' : 'height:550px'">
    <v-container>
      <!-- <v-row class="justify-center">
        <router-link class="no-underline" :to="{name: 'SignInView'}">
          <v-btn rounded="pill" prepend-icon="mdi-cast" size="large" color="white" class="animate-bounce"><span class="font-bold">Registruj se</span></v-btn>
        </router-link>
      </v-row> -->
      <v-row class="about-text" justify="center">
        <v-col class="text-left" sm="12" xs="12" md="7" lg="5" xl="6">
          <h1 v-if="biggerScreen" class="text-white font-bold text-6xl pt-6">MOVE</h1>
          <h1 v-else class="text-white font-bold text-5xl">MOVE</h1>
          <h1 v-if="biggerScreen" class="text-white font-bold leading-none pt-1 text-6xl">TV PO TVOM</h1>
          <h1 v-else class="text-white font-bold leading-none pt-1 text-4xl">TV PO TVOM</h1>
          <p class="text-white mt-4 text-base">Odaberi MOVE i uživaj u omiljenim TV sadržajima u bilo kom trenutku. Prati najnovije informacije, gledaj mečeve sa najvećih sportskih događaja i zabavi se uz filmske i serijske naslove u ponudi naših videoteka.</p>
          <p class="text-white pt-2">Za više informacija o aktivaciji usluge pozovi besplatan broj <span class="font-bold">0800 30 630</span>.</p>
          <p class="text-white pb-4">Pogledaj uputstvo za:</p>
          <v-btn color="white"  class="mr-2" @click="openStbPdf">
            <span v-if="biggerScreen" class="font-semibold">STB uređaj</span>
            <span v-else class="font-semibold">STB</span>
          </v-btn>
          <v-btn color="white" class="mr-2" @click="openTVPdf">
            <span v-if="biggerScreen" class="font-semibold">TV uređaj</span>
            <span v-else class="font-semibold">TV</span>
          </v-btn>
          <v-btn color="white" @click="openMobilePdf">
            <span v-if="biggerScreen" class="font-semibold">Mobilni uređaj</span>
            <span v-else class="font-semibold">Mobilni</span>
          </v-btn>
        </v-col>
        <v-col v-if="this.$vuetify.display.width >= 960" class="text-right" sm="12" xs="12" md="5" lg="7" xl="6">
          <img src="../assets/images/white-logo.svg" alt="logo">
        </v-col>
      </v-row>
    </v-container>
  </div>
  <div class="benefits">
    <v-container>
      <v-row>
        <v-col v-if="biggerScreen" xs="12" sm="12" md="6" lg="6" xl="6">
          <v-card  variant="tonal" height="180" class="pa-2 pt-4 ma-2">
            <v-card-title><v-icon size="x-large" class="pb-2 mr-4 text-white">mdi-television-play</v-icon><span class="text-white font-bold benefit-title">Više od 190 TV i radio kanala</span> </v-card-title>
            <v-card-text><p class="text-white text-xl">Uživaj u super ponudi informativnih, sportskih, filmskih, serijskih, dječijih TV kanala i zabavi se uz omiljene radio stanice.</p></v-card-text>
          </v-card>
        </v-col>
        <v-col v-else cols="12">
          <v-card variant="tonal" height="160" class="pt-4">
            <v-card-title><v-icon size="small" class="pb-1 mr-2 text-white">mdi-television-play</v-icon><span class="text-white font-bold text-xl benefit-title">Više od 190 TV i radio kanala</span> </v-card-title>
            <v-card-text><p class="text-white text-base">Uživaj u super ponudi informativnih, sportskih, filmskih, serijskih, dječijih TV kanala i zabavi se uz omiljene radio stanice.</p></v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="biggerScreen" xs="12" sm="12" md="6" lg="6">
          <v-card  variant="tonal" height="180" class="pa-2 pt-4 ma-2">
            <v-card-title><v-icon size="x-large" class="pb-2 mr-4 text-white">mdi-devices</v-icon><span class="text-white font-bold benefit-title">Više uređaja istovremeno</span> </v-card-title>
            <v-card-text><p class="text-white text-xl">Izaberi MOVE i gledaj omiljeni TV sadržaj na tri uređaja istovremeno (1 STB uređaj i dvije aplikacije).</p></v-card-text>
          </v-card>
        </v-col>
        <v-col v-else cols="12">
          <v-card variant="tonal" height="160" class="pt-4">
            <v-card-title><v-icon size="small" class="pb-1 mr-2 text-white">mdi-devices</v-icon><span class="text-white font-bold text-xl benefit-title">Više uređaja istovremeno</span> </v-card-title>
            <v-card-text><p class="text-white text-base">Izaberi MOVE i gledaj omiljeni TV sadržaj na tri uređaja istovremeno (1 STB uređaj i dvije aplikacije).</p></v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="biggerScreen" xs="12" sm="12" md="6" lg="6">
          <v-card variant="tonal" height="180" class="pa-2 pt-4 ma-2">
            <v-card-title><v-icon size="x-large" class="pb-2 mr-4 text-white">mdi-video</v-icon><span class="text-white font-bold benefit-title">Videoteke</span> </v-card-title>
            <v-card-text><p class="text-white text-xl">Zabavi se uz najveće naslove domaće i svjetske produkcije putem šest videoteka (Superstar, Apollon, Nick+, Pickbox NOW, FilmBox i AXN Now).</p></v-card-text>
          </v-card>
        </v-col>
        <v-col v-else cols="12">
          <v-card variant="tonal" height="160" class="pt-4">
            <v-card-title><v-icon size="small" class="pb-1 mr-2 text-white">mdi-video</v-icon><span class="text-white font-bold text-xl benefit-title">Videoteke</span> </v-card-title>
            <v-card-text><p class="text-white text-base">Zabavi se uz najveće naslove domaće i svjetske produkcije putem šest videoteka</p></v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="biggerScreen" xs="12" sm="12" md="6" lg="6">
          <v-card  variant="tonal" height="180" class="pa-2 pt-4 ma-2">
            <v-card-title><v-icon size="x-large" class="pb-2 mr-4 text-white">mdi-rotate-left</v-icon><span class="text-white font-bold benefit-title">Gledaj ispočetka</span> </v-card-title>
            <v-card-text><p class="text-white text-xl">Omogućava ti da TV sadržaj vratiš na početak emitovanja.</p></v-card-text>
          </v-card>
        </v-col> 
        <v-col v-else cols="12">
          <v-card variant="tonal" height="160" class="pt-4">
            <v-card-title><v-icon size="small" class="pb-1 mr-2 text-white">mdi-rotate-left</v-icon><span class="text-white font-bold text-xl benefit-title">Gledaj ispočetka</span> </v-card-title>
            <v-card-text><p class="text-white text-base">Omogućava ti da TV sadržaj vratiš na početak emitovanja.</p></v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="biggerScreen" xs="12" sm="12" md="6" lg="6">
          <v-card variant="tonal" height="180" class="pa-2 pt-4 ma-2">
            <v-card-title><v-icon size="x-large" class="pb-2 mr-4 text-white">mdi-repeat</v-icon><span class="text-white font-bold benefit-title">Gledaj propušteno</span> </v-card-title>
            <v-card-text><p class="text-white text-xl">Naknadno vrati sadržaj do 72h unazad i pogledaj ono što si propustio u redovnom terminu emitovanja.</p></v-card-text>
          </v-card>
        </v-col>
        <v-col v-else cols="12">
          <v-card variant="tonal" height="160" class="pt-4">
            <v-card-title><v-icon size="small" class="pb-1 mr-2 text-white">mdi-repeat</v-icon><span class="text-white font-bold text-xl benefit-title">Gledaj propušteno</span> </v-card-title>
            <v-card-text><p class="text-white text-base">Naknadno vrati sadržaj do 72h unazad i pogledaj ono što si propustio u redovnom terminu emitovanja.</p></v-card-text>
          </v-card>
        </v-col>
        <v-col v-if="biggerScreen" xs="12" sm="12" md="6" lg="6">
          <v-card variant="tonal" height="180" class="pa-2 pt-4 ma-2">
            <v-card-title><v-icon size="x-large" class="pb-2 mr-4 text-white">mdi-motion-pause-outline</v-icon><span class="text-white font-bold benefit-title">Pauziraj TV</span> </v-card-title>
            <v-card-text><p class="text-white text-xl">Pauziraj program i nastavi da ga gledaš kasnije kada za to budeš imao vremena.</p></v-card-text>
          </v-card>
        </v-col>
        <v-col v-else cols="12">
          <v-card variant="tonal" height="160" class="pt-4">
            <v-card-title><v-icon size="small" class="pb-1 mr-2 text-white">mdi-motion-pause-outline</v-icon><span class="text-white font-bold text-xl benefit-title">Pauziraj TV</span> </v-card-title>
            <v-card-text><p class="text-white text-base">Pauziraj program i nastavi da ga gledaš kasnije kada za to budeš imao vremena.</p></v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import {ref, onBeforeMount} from 'vue'
import { useRouter } from 'vue-router'

export default {
  setup(){

    const router = useRouter();
    const items = ref([
      {
        src: require('../assets/images/move-banner11.jpg'),
      },
      {
        src: require('../assets/images/move-banner8.jpg'),
      },
      {
        src: require('../assets/images/move-banner9.jpg'),
      },
      {
        src: require('../assets/images/move-banner6.jpg'),
      }
    ]);

    const mobile_items = ref([
      {
        src: require('../assets/images/mobile-slider11.jpg'),
      },
      {
        src: require('../assets/images/mobile-slider8.jpg'),
      },
      {
        src: require('../assets/images/mobile-slider9.jpg'),
      },
      {
        src: require('../assets/images/mobile-slider6.jpg'),
      }
    ]);

    function handleItemClick(index){
      if(index == 0){
        router.push({ name: 'ProfileView'});
      }else if(index == 1){
        router.push({ name: 'PackagesView'})
      }else if(index == 2){
        router.push({ name: 'VideosView'});
      }
    }

    onBeforeMount(() => {
      window.scrollTo(0, 0)
    });
  
    return {items, mobile_items, handleItemClick}
  },

  methods:{
    openTVPdf(){
      window.open('https://acrobat.adobe.com/id/urn:aaid:sc:EU:3dbf623b-ba2a-4ad1-a94d-7efe54c3db72');
    },
    openMobilePdf(){
      window.open('https://acrobat.adobe.com/id/urn:aaid:sc:eu:65481b4c-47da-4df9-87aa-361f481cdba3');
    },
    openStbPdf(){
      window.open('https://acrobat.adobe.com/id/urn:aaid:sc:EU:e08e5b75-0e47-4837-b02d-67e7b5c3bb81');
    }
  },

  computed:{
    biggerScreen () {
      if(this.$vuetify.display.width >= 620){
        return true;
      }else{
        return false;
      }
    },

    biggerScreenCarousel () {
      if(this.$vuetify.display.width >= 960){
        return true;
      }else{
        return false;
      }
    },

    carouselHeight (){
      if(this.$vuetify.display.width > 1300 && this.$vuetify.display.width < 1600){
        return 690;
      }else if(this.$vuetify.display.width > 1600){
        return 903;
      }else if(this.$vuetify.display.width < 960 && this.$vuetify.display.width > 400){
        return 600;
      }else{
        return 450;
      }
    }
  }
}
</script>

<style>
  
  .hero-image{
    position: relative;
    height: 450px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 40px;
  }
  .hero-image::before{
    content: "";
    background-image: url('@/assets/images/move-banner1.jpg');
    background-size: cover;
    background-position: center ;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.75;
  }

  .about{
    /* height: 700px; */
    background: linear-gradient(to right, rgb(177, 13, 40), rgb(237, 26, 59), rgb(245, 130, 32));
    padding-top: 30px;
  }

  .about-text{
    padding-top: 60px;
    font-family: 'Pero-Bold';
  }

  .about-text p{
    font-family: 'Roboto-Light';
    font-size: 18px;
  }

  @media only screen and (max-width: 960px){
    .about-text {
      padding-top: 18px;
      padding-bottom: 60px;
    }
  }

  .benefits{
    max-height: 1500px;
    padding-top: 80px;
    padding-bottom: 80px;
    /* background-color: #343434; */
    background: rgb(0, 0, 0, 0.84);
  }

  .benefits p{
    font-family: 'Roboto-Light';
  }

  @media only screen and (max-width: 960px){
    .benefits{
      padding-top: 40px;
      padding-bottom: 40px;
    }
  }

  .benefit-title{
    font-family: 'Pero-Bold';
    font-size: 32px;
  }

  .v-carousel .v-btn {
    color: black;
    background-color: white;
  }

</style>
