<template>
    <div class="reset">
      <v-snackbar v-model="this.resetPassCodeResponseError" :timeout="2000" location="top center" transition="slide-y-transition" 
        height="80" variant="elevated" color="#f58220" elevation="24">
        <p class="message">Desila se greška, pokušaj ponovo.</p> 
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="this.resetPassCodeResponseError = false">
            <v-icon size="large">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="this.codeValidationError" :timeout="2000" location="top center" transition="slide-y-transition" 
        height="80" variant="elevated" color="#f58220" elevation="24">
        <p class="message">Kod nije ispravan, pokušaj ponovo.</p> 
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="this.codeValidationError = false">
            <v-icon size="large">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-snackbar v-model="this.invalidPasswordConfirmedError" :timeout="2000" location="top center" transition="slide-y-transition" 
        height="80" variant="elevated" color="#f58220" elevation="24">
        <p class="message">Pogrešna potvrda lozinke, pokušaj ponovo.</p> 
        <template v-slot:actions>
          <v-btn color="white" variant="text" @click="this.invalidPasswordConfirmedError = false">
            <v-icon size="large">mdi-close</v-icon>
          </v-btn>
        </template>
      </v-snackbar>
      <v-container>
        <v-row justify="center">
          <v-col xs="12" sm="8" md="6" lg="6" xl="4">
            <div class="form">
              <v-window v-model="step">
                <v-window-item :value="1">
                  <h1 class="text-3xl font-bold mb-4">Zaboravljena lozinka <span class="text-lg font-medium mr-1"> > Unesi e-mail </span> <v-chip color="red">{{step}}</v-chip> </h1>
                  <!-- <p class="font-medium pt-2">IPTV nalog ti omogućava pristup najboljim sportskim i zabavnim sadržajima putem mobilnog telefona, bilo kada i bilo gdje u BiH. Sačuvaj sve svoje podatke na jednom mjestu.</p> -->
                  <p class="pt-3">Unesi e-mail adresu u polje kako bi započeo proces resetovanja zaboravljene lozinke.
                     Nakon toga, biće ti poslan e-mail sa jednokratnim aktivacionim kodom koji trebaš unijeti da bi se nastavio proces promjene lozinke.</p>
                  <br>
                  <v-text-field
                    class="mt-3"
                    label="Unesi e-mail adresu"
                    clearable
                    placeholder="johndoe@gmail.com"
                    type="email"
                    prepend-inner-icon="mdi-email-outline"
                    v-model="newPassword.email"
                    :rules="[rules.required, rules.email]"
                    @keyup.enter="resetPassCodeRequest()"
                  ></v-text-field>
                </v-window-item>
                <v-window-item :value="2">
                  <h1 class="text-3xl font-bold">Zaboravljena lozinka <span class="text-lg font-medium mr-1"> > Potvrdi e-mail </span> <v-chip color="red">{{step}}</v-chip></h1>
                  <p class="font-medium pt-2">Na e-mail adresu <span class="font-bold text-red-800 text-xl">{{ this.newPassword.email }}</span> smo poslali aktivacioni kod koji je potrebno unijeti u nastavku:</p>
                  <br>
                  <v-text-field
                    class="mt-3"
                    label="Unesite aktivacioni kod"
                    clearable
                    type="text"
                    prepend-inner-icon="mdi-shield-key-outline"
                    v-model="newPassword.activation_code"
                    :rules="[rules.required, rules.length, rules.type]"
                    @keyup.enter="resetPassCodeValidation()"
                  ></v-text-field>
                </v-window-item>
                <v-window-item :value="3">
                  <h1 class="text-3xl font-bold">Zaboravljena lozinka <span class="text-lg font-medium mr-1"> > Kreiraj lozinku </span> <v-chip color="red">{{step}}</v-chip></h1>
                  <p class="font-medium pt-2">Odaberi i kreiraj lozinku koju ćeš koristiti za prijavu na svoj nalog. 
                      Lozinka mora sadržavati minimalno 8 znakova uključujući bar jedno veliko slovo, malo slovo, broj i specijalni znak.</p>
                  <br>
                  <v-text-field
                    v-model="newPassword.password"               
                    :append-inner-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show1 ? 'text' : 'password'"
                    name="input-10-2"
                    label="Unesi novu lozinku"
                    class="input-group--focused mt-2"
                    @click:append-inner="show1 = !show1"
                    prepend-inner-icon="mdi-lock-outline"
                    :rules="[rules.password]"
                  ></v-text-field>
                  <v-text-field
                    v-model="newPassword.confirmed_password"
                    :append-inner-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show2 ? 'text' : 'password'"
                    name="input-10-2"
                    label="Potvrdi novu lozinku"
                    class="input-group--focused mt-2"
                    @click:append-inner="show2 = !show2"
                    prepend-inner-icon="mdi-lock"
                    @keyup.enter="resetUserPassword()"
                  ></v-text-field>
                </v-window-item>
                <v-window-item :value="4" style="text-align: center;">
                  <h1 class="text-3xl font-bold">Lozinka je uspješno promijenjena!</h1>
                  <p class="font-medium pt-2">Sada možeš da se prijaviš na svoj MOVETV nalog.</p>
                  <br>
                </v-window-item>
              </v-window>
              <v-row no-gutters>
                <v-col v-if="this.step != 4" cols="6" class="text-left">
                    <v-btn v-if="this.step > 1 && this.step < 3" @click="this.step--" class="mt-3 bg-black" variant="tonal"  size="large" elevation="3"> 
                    <span class="font-bold">Nazad</span>
                    </v-btn>
                </v-col>
                <v-col v-if="this.step != 4" cols="6" class="text-right">
                    <v-btn v-if="this.step == 1" @click="resetPassCodeRequest()" :loading="this.loading" :disabled="validateEmailFormat()" class="right bg-error text-white mt-3" variant="tonal" size="large" elevation="3">
                      <span class="font-bold">Nastavi</span>
                    </v-btn>
                    <v-btn v-if="this.step == 2" @click="resetPassCodeValidation()" :loading="this.loading" :disabled="validateCodeFormat()" class="right bg-error text-white mt-3" variant="tonal" size="large" elevation="3">
                      <span class="font-bold">Nastavi</span>
                    </v-btn>
                    <v-btn v-if="this.step == 3" @click="resetUserPassword()" :loading="this.loading" :disabled="checkConfirmedPassword()" class="right bg-error text-white mt-3" variant="tonal" size="large" elevation="3">
                      <span class="font-bold">Potvrdi</span>
                    </v-btn>
                </v-col>
                <v-col v-else cols="12" class="text-center">
                  <router-link class="no-underline" :to="{name: 'SignInView'}">
                      <v-btn v-if="this.step == 4" class="center bg-success mt-3" variant="tonal" size="large" elevation="3">
                        <span class="font-bold">Prijavi se</span>
                      </v-btn>
                    </router-link>
                </v-col>
              </v-row>
              <!-- <v-row no-gutters>
                <v-col cols="6" class="text-left">
                  <v-btn v-if="step > 1 && step < 4" @click="step--" class="mt-3 bg-black" variant="tonal"  size="large" elevation="3"> 
                    <span class="font-bold">Nazad</span>
                  </v-btn>
                </v-col>
                <v-col cols="6" class="text-right">
                  <v-btn v-if="step < 4" @click="step++" class="right bg-error text-white mt-3" variant="tonal" size="large" elevation="3">
                    <span class="font-bold">Nastavi</span>
                  </v-btn>
                  <router-link class="no-underline" :to="{name: 'SignInView'}">
                    <v-btn v-if="step == 4" @click="createAccount" class="right bg-success mt-3" variant="tonal" size="large" elevation="3">
                        <span class="font-bold">Prijavi se</span>
                    </v-btn>
                  </router-link>
                </v-col>
              </v-row> -->
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </template>
  
  <script>
  import {ref, onBeforeMount, onMounted} from 'vue';
  import moment from 'moment';
  import CryptoJS from 'crypto-js';
import { toHandlers } from 'vue';
  
  export default {
      setup(){
      let show1 = ref(false);
      let show2 = ref(false);
      let password = ref('Lozinka');
      let info = ref(null);
  
      const step = ref(1);
  
      let newPassword = ref({
        email: '',
        password: null,
        confirmed_password: null,
        loggedIn: false,
        activation_code: ''
      });
      let invalidPassword = ref(false);
      let profileId = ref(null);
      let loading = ref(false);
      let validationToken = ref(null);
      let codeValidationError = ref(false);
      let invalidPasswordConfirmedError = ref(false);
      let resetPassCodeResponseError = ref(false);
      const rules = ref({
        required: value => !!value || "Obavezno polje.",
        email: value => {
                const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                return pattern.test(value) || 'Neispravna e-mail adresa.'
              },
        length: value => value.length == 6 || "Potrebno unijeti 6 karaktera.",
        password: value => {
          const minLength = 8;
          const hasUpperCase = /[A-Z]/.test(value);
          const hasLowerCase = /[a-z]/.test(value);
          const hasSpecialChar = /[!@#$%^&*()_+{}\[\]:;<>,.?~\\-]/.test(value);
          const hasNumber = /[0-9]/.test(value);

          if (value < minLength) {
          return "Neispravan format lozinke.";
          }
          else if (!hasUpperCase) {
            return "Neispravan format lozinke.";
          }
          else if (!hasLowerCase) {
            return "Neispravan format lozinke.";
          }
          else if (!hasSpecialChar) {
            return "Neispravan format lozinke.";      
          }
          else if (!hasNumber) {
            return "Neispravan format lozinke.";    
          }
          else{
            return true;
          }

        },
        type: value => typeof value == 'string' || ""
      });
  
  
      onBeforeMount(() => {
        window.scrollTo(0, 0)
      });

      function validateEmailFormat(){
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
          let validEmail = pattern.test(this.newPassword.email);
          
          if(this.newPassword.email == '' || validEmail == false){
            return true;
          }else{
            return false;
          }
      }

      function validateCodeFormat(){
        if(this.newPassword.activation_code == '' || this.newPassword.activation_code.length != 6){
          return true;
        }else{
          return false;
        }
      }
      

      function checkConfirmedPassword(){
        if(this.newPassword.password == this.newPassword.confirmed_password && this.newPassword.password != null){
          return false;
        }
        else{
          return true;
        }
      }

      async function resetPassCodeRequest(){
  
        //provjera da li email postoji u bazi

        const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
        const date = moment().format('YYYY-MM-DD HH:mm:ss');
        const signatureString = date + this.newPassword.email;
          
        const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);

        this.loading = true;
        let raw = JSON.stringify({
        "Request": {
          "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
          "Date": date,
          "Method": "resetPassCodeRequest",
          "Signature": signature,
          "Params": {
            "username": this.newPassword.email
            }
          }
        });

        try{
          await fetch(process.env.VUE_APP_ROOT_API, {
            method: "POST",
            headers:{
              'Access-Control-Allow-Origin': '*',
              "Content-Type": "application/json",
              "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
            },
            body: raw,
            redirect: 'follow'
            
          })
            .then(res => res.json())
            .then(data => this.resetPassCodeResponse = data.Response.resData)
            .catch(error => console.log('error', error));
        }catch(error){
          alert("Greška na serveru!");
        }

        if(this.resetPassCodeResponse == 'OK'){
          this.loading = false;
          this.step++;
        }else{
          this.resetPassCodeResponseError = true;
          this.loading = false;
        }
        

      }

      async function resetPassCodeValidation(){
  
        //unos verifikacionog koda

        const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
        const date = moment().format('YYYY-MM-DD HH:mm:ss');
        const signatureString = date + this.newPassword.email + this.newPassword.activation_code;
            
        const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);
          
          this.loading = true;
          let raw = JSON.stringify({
            "Request": {
              "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
              "Date": date,
              "Method": "resetPassCodeValidation",
              "Signature": signature,
              "Params": {
                "username": this.newPassword.email,
                "verification_code": this.newPassword.activation_code
              }
            }
          });

          try{
            await fetch(process.env.VUE_APP_ROOT_API, {
              method: "POST",
              headers:{
                "Content-Type": "application/json",
                "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
              },
              body: raw,
              redirect: 'follow'
              
            })
              .then(res => res.json())
              .then(data => this.resetPassCodeValidationResponse = data.Response.resData)
              .catch(error => console.log('error', error));
          }catch(error){
            alert("Greška na serveru!");
          }

          if(this.resetPassCodeValidationResponse != 'NOK'){
            let indexOfSplit = this.resetPassCodeValidationResponse.indexOf('|');
            this.validationToken = this.resetPassCodeValidationResponse.substring(indexOfSplit + 1);
            this.step++;
            this.loading = false;
          }else{
            this.loading = false;
            this.codeValidationError = true;
          }

      }

      return { show2, show1, password, newPassword, step, info, profileId, loading, validationToken, codeValidationError, invalidPasswordConfirmedError, rules, invalidPassword, resetPassCodeResponseError, validateEmailFormat, validateCodeFormat, checkConfirmedPassword, resetPassCodeRequest, resetPassCodeValidation}
    },

    methods:{

      async resetUserPassword(){

        //provjera da li je tačna potvrda

        if(this.newPassword.password == this.newPassword.confirmed_password){

          //slanje novog passworda
          this.loading = true;
          const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
          const date = moment().format('YYYY-MM-DD HH:mm:ss');
          const signatureString = date + this.newPassword.email + this.validationToken + this.newPassword.confirmed_password;
              
          const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);
            
            let raw = JSON.stringify({
              "Request": {
                "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
                "Date": date,
                "Method": "resetUserPassword",
                "Signature": signature,
                "Params": {
                  "email": this.newPassword.email,
                  "vtoken": this.validationToken,
                  "newpassword": this.newPassword.confirmed_password
                }
              }
            });

            try{
              await fetch(process.env.VUE_APP_ROOT_API, {
                method: "POST",
                headers:{
                  "Content-Type": "application/json",
                  "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
                },
                body: raw,
                redirect: 'follow'
                
              })
                .then(res => res.json())
                .then(data => this.resetUserPasswordResponse = data.Response.result[0].result_code)
                .catch(error => console.log('error', error));
            }catch(error){
              alert("Greška na serveru!");
            }

            if(this.resetUserPasswordResponse == 0){
              this.loading = false;
              this.$router.push({ path: 'prijavi-se', query: {password_reset: 'success'}});
            }else{
              this.loading = false;
              this.invalidPasswordConfirmedError = true;
            }

        }else{
          this.invalidPasswordConfirmedError = true;
          this.loading = false;
        }
      }
    }
  
    
  }
  </script>
  
  <style>
    .reset{
        width: 100%;
        height: 720px;
        padding-top: 110px;
        background: rgb(0, 0, 0, 0.2);
        background-color: rgb(233, 233, 233);
    }

    @media only screen and (max-width: 960px){
      .reset{
        padding-top: 60px;
        height: 650px;
      }
    }
  </style>