import { defineStore } from 'pinia'
import CryptoJS from 'crypto-js';
import moment from 'moment';


export const useUserStore =  defineStore('userStore', {
    state: () => ({
        responseData: null,
        loggedUserData: null,
        errorMessage: '',
        changedPasswordResponse: null,
        deletedDeviceResponse: null,
        deleteSubscriptionResponse: null,
        addSubscriptionResponse: null,
        sendMessageResponse:null,
        activeSubsData: null,
        activeHardwaresData: null,
        orderHardwareResponse: null,
        removeCardResponse: null,
        isLoggedIn: false,
        loadedUser: null,
        addPromoCodeResponse: null
        // currentUserType: null
    }),

 
    actions: {

        logOutUser(loggedStatus){
          // this.isLoggedIn = loggedStatus;
          localStorage.setItem('loggedIn', loggedStatus);
          localStorage.removeItem('user');
          // localStorage.removeItem('user_type');
          localStorage.removeItem('packages');
          localStorage.removeItem('hardwares');
        },

        async logIn(email, password){
            
          const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
          const date = moment().format('YYYY-MM-DD HH:mm:ss');
          const signatureString = date + email + password;
          
          const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);

          let raw = JSON.stringify({
            "Request": {
              "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
              "Date": date,
              "Method": "loginUserByEmail",
              "Signature": signature,
              "Params": {
                "email": email,
                "password": password
                }
              }
            });
  
          try{
            await fetch(process.env.VUE_APP_ROOT_API, {
              method: "POST",
              headers:{
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json",
                "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
              },
              body: raw,
              redirect: 'follow'
              
            })
              .then(res => res.json())
              .then(data => this.responseData = data.Response)
              .catch(error => console.log('error', error));
          }catch(error){
            alert("Greška na serveru!");
          }

          if(this.responseData == null) return null;
  
          let loginStatus = this.responseData.result[0]['result_code'];
          this.loggedUserData = this.responseData.result_objects;

          await this.getSubActivePackages(email);
          await this.getSubActiveHardwares(email);

          if(loginStatus == 0){
            // this.isLoggedIn = true;
            localStorage.setItem('user', JSON.stringify(this.loggedUserData));
            localStorage.setItem('loggedIn', true);
            let timestamp = new Date().getTime();
            localStorage.setItem('timestamp', timestamp);

            // console.log(this.loggedUserData.ott_email);
            // this.getSubActivePackages(email);
          }

          return loginStatus;
          
          // if(loginStatus == 0){
          //   this.$router.push({ name: 'ProfileView' });
          
          // }else{
          //   this.errorMessage = "Neispravna lozinka/e-mail adresa."
          // }

        },

        async changePassword(email, oldPassword, newPassword){

          const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
          const date = moment().format('YYYY-MM-DD HH:mm:ss');
          const signatureString = date + email + oldPassword + newPassword;
        
          const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);

          let raw = JSON.stringify({
            "Request": {
              "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
              "Date": date,
              "Method": "changeUserPasswordByEmail",
              "Signature": signature,
              "Params": {
                "email": email,
                "password": oldPassword,
                'newpassword': newPassword
                }
              }
            });

            try{
              await fetch(process.env.VUE_APP_ROOT_API, {
                method: "POST",
                headers:{
                  'Access-Control-Allow-Origin': '*',
                  "Content-Type": "application/json",
                  "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
                },
                body: raw,
                redirect: 'follow' 
              })
                .then(res => res.json())
                .then(data => this.changedPasswordResponse = data.Response)
                .catch(error => console.log('error', error));

            }catch(error){
              alert("Greška na serveru!");
            }

            let response = [];
            response['status'] = this.changedPasswordResponse.result[0].result_code;
            response['message'] = this.changedPasswordResponse.result[0].result_message;

            return response;

        },

        async deleteDeviceById(device_id, email){

          const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
          const date = moment().format('YYYY-MM-DD HH:mm:ss');
          const signatureString = date + email + device_id;
          
          const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);

          let raw = JSON.stringify({
            "Request": {
              "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
              "Date": date,
              "Method": "deleteDeviceByEmail",
              "Signature": signature,
              "Params": {
                "email": email,
                "deviceid": device_id
                }
              }
            });

            try{
              await fetch(process.env.VUE_APP_ROOT_API, {
                method: "POST",
                headers:{
                  'Access-Control-Allow-Origin': '*',
                  "Content-Type": "application/json",
                  "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
                },
                body: raw,
                redirect: 'follow'
                
              })
                .then(res => res.json())
                .then(data => this.deletedDeviceResponse = data.Response)
                .catch(error => console.log('error', error));
            }catch(error){
              alert("Greška na serveru!");
            }

            if(this.deletedDeviceResponse.result[0].result_code == 0){
              localStorage.removeItem('user');
              localStorage.setItem('user', JSON.stringify(this.deletedDeviceResponse.result_objects));
            }

            // localStorage.removeItem('user');
            // localStorage.setItem('user', JSON.stringify(this.deletedDeviceResponse.result_objects));
            // let response = [];
            // response['status'] = this.deletedDeviceResponse.result[0].result_code;
            // response['message'] = this.deletedDeviceResponse.result[0].result_message;
            // response['ott_message'] = this.deletedDeviceResponse.result_objects.ott_delete_device_result.message;


            return this.deletedDeviceResponse;
        },

       async sendEmail(userContactDetails){

          const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
          const date = moment().format('YYYY-MM-DD HH:mm:ss');
          const signatureString = date + userContactDetails.name + userContactDetails.street + userContactDetails.city + userContactDetails.phone + userContactDetails.email + userContactDetails.note + userContactDetails.agreed;
          
          const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);

          let raw = JSON.stringify({
            "Request": {
              "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
              "Date": date,
              "Method": "registerInterestedUser",
              "Signature": signature,
              "Params": {
                "name": userContactDetails.name,
                "street": userContactDetails.street,
                "city": userContactDetails.city,
                "phone": userContactDetails.phone,
                "email": userContactDetails.email,
                "note": userContactDetails.note,
                "consentForDataSave": userContactDetails.agreed
                }
              }
            });

            try{
              await fetch(process.env.VUE_APP_ROOT_API, {
                method: "POST",
                headers:{
                  'Access-Control-Allow-Origin': '*',
                  "Content-Type": "application/json",
                  "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
                },
                body: raw,
                redirect: 'follow'
                
              })
                .then(res => res.json())
                .then(data => this.sendMessageResponse = data.Response)
                .catch(error => console.log('error', error));
            }catch(error){
              alert("Greška na serveru!");
            }

            return this.sendMessageResponse;

        },

        async sendPostpaidApplication(userApplicationDetails){

          const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
          const date = moment().format('YYYY-MM-DD HH:mm:ss');
          const signatureString = date + userApplicationDetails.name + userApplicationDetails.street + userApplicationDetails.city + userApplicationDetails.phone + userApplicationDetails.email + userApplicationDetails.note + userApplicationDetails.package + userApplicationDetails.agreed;
          const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);

          let raw = JSON.stringify({
            "Request": {
              "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
              "Date": date,
              "Method": "registerInterestedPostpaid",
              "Signature": signature,
              "Params": {
                "name": userApplicationDetails.name,
                "street": userApplicationDetails.street,
                "city": userApplicationDetails.city,
                "phone": userApplicationDetails.phone,
                "email": userApplicationDetails.email,
                "note": userApplicationDetails.note,
                "mainpackage": userApplicationDetails.package,
                "additionalpackages": userApplicationDetails.additionalPackages,
                "consentForDataSave": userApplicationDetails.agreed,
                "already_exist": userApplicationDetails.already_exist
                }
              }
          });

          try{
            await fetch(process.env.VUE_APP_ROOT_API, {
              method: "POST",
              headers:{
                'Access-Control-Allow-Origin': '*',
                "Content-Type": "application/json",
                "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
              },
              body: raw,
              redirect: 'follow'
              
            })
              .then(res => res.json())
              .then(data => this.sendApplicationResponse = data.Response)
              .catch(error => console.log('error', error));
          }catch(error){
            alert("Greška na serveru!");
          }

          return this.sendApplicationResponse;

        },

        async initCardRegistration(userEmail, newCard){

          const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
          const date = moment().format('YYYY-MM-DD HH:mm:ss');
          const signatureString = date + userEmail;
          
          const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);

          let raw = JSON.stringify({
            "Request": {
              "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
              "Date": date,
              "Method": "addCardByEmail",
              "Signature": signature,
              "Params": {
                "email": userEmail,
                "cardFname": newCard.cardFname,
                "cardLName": newCard.cardLName,
                "cardStreet": newCard.cardStreet,
                "cardStreet_nr": newCard.cardStreet_nr,
                "cardCity": newCard.cardCity,
                "cardZip": newCard.cardZip,
                "cardCountry": newCard.cardCountry,
                "cardEmail": newCard.cardEmail,
                "cardPhone": newCard.cardPhone
                }
              }
            });

            try{
              await fetch(process.env.VUE_APP_ROOT_API, {
                method: "POST",
                headers:{
                  'Access-Control-Allow-Origin': '*',
                  "Content-Type": "application/json",
                  "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
                },
                body: raw,
                redirect: 'follow'
                
              })
                .then(res => res.json())
                .then(data => this.initCardRegResponse = data.Response)
                .catch(error => console.log('error', error));
            }catch(error){
              alert("Greška na serveru!");
            }

            return this.initCardRegResponse;

        },

        async loadUserByEmail(email){

          const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
          const date = moment().format('YYYY-MM-DD HH:mm:ss');
          const signatureString = date + email;
          
          const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);

          let raw = JSON.stringify({
            "Request": {
              "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
              "Date": date,
              "Method": "loadUserProfileByEmail",
              "Signature": signature,
              "Params": {
                "email": email
                }
              }
            });

            try{
              await fetch(process.env.VUE_APP_ROOT_API, {
                method: "POST",
                headers:{
                  'Access-Control-Allow-Origin': '*',
                  "Content-Type": "application/json",
                  "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
                },
                body: raw,
                redirect: 'follow'
                
              })
                .then(res => res.json())
                .then(data => this.loadedUser = data.Response)
                .catch(error => console.log('error', error));
            }catch(error){
              alert("Greška na serveru!");
            }

            if(this.loadedUser != null && this.loadedUser.result[0].result_code == 0){
              return this.loadedUser.result_objects;
            }
            
        },

        async loadUserByEmailCardSuccess(email){

          const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
          const date = moment().format('YYYY-MM-DD HH:mm:ss');
          const signatureString = date + email;
          
          const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);

          let raw = JSON.stringify({
            "Request": {
              "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
              "Date": date,
              "Method": "loadUserProfileByEmail",
              "Signature": signature,
              "Params": {
                "email": email
                }
              }
            });

            try{
              await fetch(process.env.VUE_APP_ROOT_API, {
                method: "POST",
                headers:{
                  'Access-Control-Allow-Origin': '*',
                  "Content-Type": "application/json",
                  "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
                },
                body: raw,
                redirect: 'follow'
                
              })
                .then(res => res.json())
                .then(data => this.loadedUser = data.Response)
                .catch(error => console.log('error', error));
            }catch(error){
              alert("Greška na serveru!");
            }
            
            // if(this.loadedUser.result[0].result_code == 0){
            //   localStorage.removeItem('user');
            //   localStorage.setItem('user', JSON.stringify(this.loadedUser.result_objects));
            // }

            return this.loadedUser.result_objects;
        },


        async removeCardByEmail(email){

          const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
          const date = moment().format('YYYY-MM-DD HH:mm:ss');
          const signatureString = date + email;
          
          const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);

          let raw = JSON.stringify({
            "Request": {
              "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
              "Date": date,
              "Method": "removeCardByEmail",
              "Signature": signature,
              "Params": {
                "email": email
                }
              }
            });

            try{
              await fetch(process.env.VUE_APP_ROOT_API, {
                method: "POST",
                headers:{
                  'Access-Control-Allow-Origin': '*',
                  "Content-Type": "application/json",
                  "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
                },
                body: raw,
                redirect: 'follow'
                
              })
                .then(res => res.json())
                .then(data => this.removeCardResponse = data.Response)
                .catch(error => console.log('error', error));
            }catch(error){
              alert("Greška na serveru!");
            }

            if(this.removeCardResponse != null && this.removeCardResponse.result[0].result_code == 0){
              localStorage.removeItem('user');
              localStorage.setItem('user', JSON.stringify(this.removeCardResponse.result_objects));
            }

            return this.removeCardResponse;
        },


        async enableRecurringProcess(packageId, email){

          const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
          const date = moment().format('YYYY-MM-DD HH:mm:ss');
          const signatureString = date + email + packageId;
          
          const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);

          let raw = JSON.stringify({
            "Request": {
              "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
              "Date": date,
              "Method": "enableRecurringProcess",
              "Signature": signature,
              "Params": {
                "email": email,
                "packageid": packageId
                }
              }
            });

            try{
              await fetch(process.env.VUE_APP_ROOT_API, {
                method: "POST",
                headers:{
                  'Access-Control-Allow-Origin': '*',
                  "Content-Type": "application/json",
                  "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
                },
                body: raw,
                redirect: 'follow'
                
              })
                .then(res => res.json())
                .then(data => this.enableSubscriptionResponse = data.Response)
                .catch(error => console.log('error', error));
            }catch(error){
              alert("Greška na serveru!");
            }

            // console.log(this.enableSubscriptionResponse);

            await this.getSubActivePackages(email);

            if(this.enableSubscriptionResponse != null && this.enableSubscriptionResponse.result[0].result_code == 0){
              localStorage.removeItem('user');
              localStorage.setItem('user', JSON.stringify(this.enableSubscriptionResponse.result_objects));
            }
      
            return this.enableSubscriptionResponse;
        },


        async removeSubscriptionById(packageId, email){

          const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
          const date = moment().format('YYYY-MM-DD HH:mm:ss');
          const signatureString = date + email + packageId;
          
          const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);

          let raw = JSON.stringify({
            "Request": {
              "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
              "Date": date,
              "Method": "deleteSubscriptionByEmail",
              "Signature": signature,
              "Params": {
                "email": email,
                "packageid": packageId
                }
              }
            });

            try{
              await fetch(process.env.VUE_APP_ROOT_API, {
                method: "POST",
                headers:{
                  'Access-Control-Allow-Origin': '*',
                  "Content-Type": "application/json",
                  "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
                },
                body: raw,
                redirect: 'follow'
                
              })
                .then(res => res.json())
                .then(data => this.deleteSubscriptionResponse = data.Response)
                .catch(error => console.log('error', error));
            }catch(error){
              alert("Greška na serveru!");
            }

            // console.log(this.deleteSubscriptionResponse);

            await this.getSubActivePackages(email);

            if(this.deleteSubscriptionResponse != null && this.deleteSubscriptionResponse.result[0].result_code == 0){
              localStorage.removeItem('user');
              localStorage.setItem('user', JSON.stringify(this.deleteSubscriptionResponse.result_objects));
            }
      
            return this.deleteSubscriptionResponse;
        },

        async addSubscriptionById(packageId, email){

          const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
          const date = moment().format('YYYY-MM-DD HH:mm:ss');
          const signatureString = date + email + packageId;
    
          const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);

          let raw = JSON.stringify({
            "Request": {
              "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
              "Date": date,
              "Method": "addSubscriptionByEmail",
              "Signature": signature,
              "Params": {
                "email": email,
                "packageid": packageId
                }
              }
            });

            try{
              await fetch(process.env.VUE_APP_ROOT_API, {
                method: "POST",
                headers:{
                  'Access-Control-Allow-Origin': '*',
                  "Content-Type": "application/json",
                  "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
                },
                body: raw,
                redirect: 'follow'
                
              })
                .then(res => res.json())
                .then(data => this.addSubscriptionResponse = data.Response)
                .catch(error => console.log('error', error));
            }catch(error){
              alert("Greška na serveru!");
            }

            await this.getSubActivePackages(email);

            if(this.addSubscriptionResponse != null && this.addSubscriptionResponse.result[0].result_code == 0){
              localStorage.removeItem('user');
              localStorage.setItem('user', JSON.stringify(this.addSubscriptionResponse.result_objects));
            }

            return this.addSubscriptionResponse;
        },

        async addPromoCode(email, promoCode){

          const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
          const date = moment().format('YYYY-MM-DD HH:mm:ss');
          const signatureString = date + email + promoCode;

          const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);
          
          let raw = JSON.stringify({
            "Request": {
              "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
              "Date": date,
              "Method": "checkAndSavePromoCode",
              "Signature": signature,
              "Params": {
                "username": email,
                "promo_code": promoCode
                }
              }
            });

            try{
              await fetch(process.env.VUE_APP_ROOT_API, {
                method: "POST",
                headers:{
                  'Access-Control-Allow-Origin': '*',
                  "Content-Type": "application/json",
                  "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
                },
                body: raw,
                redirect: 'follow'
                
              })
                .then(res => res.json())
                .then(data => this.addPromoCodeResponse = data.Response)
                .catch(error => console.log('error', error));
            }catch(error){
              alert("Greška na serveru!");
            }

            if(this.addPromoCodeResponse != null && this.addPromoCodeResponse.result[0].result_code == 0){
              localStorage.removeItem('user');
              localStorage.setItem('user', JSON.stringify(this.addPromoCodeResponse.result_objects));
            }

            return this.addPromoCodeResponse;

        },


        //PAKETI

        async getSubActivePackages(email){

          const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
          const date = moment().format('YYYY-MM-DD HH:mm:ss');
          const signatureString = date + email;
          
          const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);

          let raw = JSON.stringify({
            "Request": {
              "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
              "Date": date,
              "Method": "getSubActivePackages",
              "Signature": signature,
              "Params": {
                "email": email
                }
              }
            });

            try{
              await fetch(process.env.VUE_APP_ROOT_API, {
                method: "POST",
                headers:{
                  'Access-Control-Allow-Origin': '*',
                  "Content-Type": "application/json",
                  "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
                },
                body: raw,
                redirect: 'follow'
                
              })
                .then(res => res.json())
                .then(data => this.activeSubsData = data.Response)
                .catch(error => console.log('error', error));
            }catch(error){
              alert("Greška na serveru!");
            }

            // console.log(this.activeSubsData.result_objects.getSubActivePackages);
        
            if(this.activeSubsData != null && this.activeSubsData.result[0].result_code == 0){

              if(localStorage.getItem('packages') == null){
                localStorage.setItem('packages', JSON.stringify(this.activeSubsData.result_objects.getSubActivePackages));
              }else{
                localStorage.removeItem('packages');
                localStorage.setItem('packages', JSON.stringify(this.activeSubsData.result_objects.getSubActivePackages));
              }
              
            }

            // return this.activeSubsData.result_objects.getSubActivePackages;
        },

        //UREĐAJI

        async getSubActiveHardwares(email){
          
          const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
          const date = moment().format('YYYY-MM-DD HH:mm:ss');
          const signatureString = date + email;

          const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);

          let raw = JSON.stringify({
            "Request": {
              "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
              "Date": date,
              "Method": "getAvailableHardware",
              "Signature": signature,
              "Params": {
                "email": email
                }
              }
            });

            try{
              await fetch(process.env.VUE_APP_ROOT_API, {
                method: "POST",
                headers:{
                  'Access-Control-Allow-Origin': '*',
                  "Content-Type": "application/json",
                  "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
                },
                body: raw,
                redirect: 'follow'
                
              })
                .then(res => res.json())
                .then(data => this.activeHardwaresData = data.Response)
                .catch(error => console.log('error', error));
            }catch(error){
              alert("Greška na serveru!");
            }

            if(this.activeHardwaresData != null && this.activeHardwaresData.apiResult[0].result_code == 0){

              if(localStorage.getItem('hardwares') == null){
                localStorage.setItem('hardwares', JSON.stringify(this.activeHardwaresData.resData));
              }else{
                localStorage.removeItem('hardwares');
                localStorage.setItem('hardwares', JSON.stringify(this.activeHardwaresData.resData));
              }
              
            }
        },


        async orderHardwareById(email, hardwareId, numOfPayments){

          const apiSecret = process.env.VUE_APP_ROOT_API_KEY;
          const date = moment().format('YYYY-MM-DD HH:mm:ss');
          const signatureString = date + email + hardwareId + numOfPayments;

          const signature = CryptoJS.HmacSHA256(signatureString, apiSecret).toString(CryptoJS.enc.Hex);

          let raw = JSON.stringify({
            "Request": {
              "AppKey": "=AIBmFoWPQmXnYjniCtSMRxH5TF9qK6iN=",
              "Date": date,
              "Method": "sellHardwareByEmail",
              "Signature": signature,
              "Params": {
                "email": email,
                "hardwareId": hardwareId,
                "numberOfPayments": numOfPayments
                }
              }
            });

            try{
              await fetch(process.env.VUE_APP_ROOT_API, {
                method: "POST",
                headers:{
                  'Access-Control-Allow-Origin': '*',
                  "Content-Type": "application/json",
                  "Cookie": "PHPSESSID=vugvriocnmdpgret2jaucuntm4",
                },
                body: raw,
                redirect: 'follow'
                
              })
                .then(res => res.json())
                .then(data => this.orderHardwareResponse = data.Response)
                .catch(error => console.log('error', error));
            }catch(error){
              alert("Greška na serveru!");
            }

            if(this.orderHardwareResponse != null && this.orderHardwareResponse.apiResult[0].result_code == 0){
              
              if(localStorage.getItem('hardwares') == null){
                localStorage.setItem('hardwares', JSON.stringify(this.orderHardwareResponse.resData));
              }else{
                localStorage.removeItem('hardwares');
                localStorage.setItem('hardwares', JSON.stringify(this.orderHardwareResponse.resData));
              }
            }

            return this.orderHardwareResponse;
        }

    }
})