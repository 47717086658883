<template>
  <div class="videos bg-center bg-cover text-start">
    <v-container>
      <v-row>
        <v-col xs="12" sm="12" md="12" lg="12">
          <div class="relative top-2 left-{{ this.$vuetify.display.width >= 960 ? 20 : 12  }}">
            <p class="text-3xl font-bold text-white">MOVE TV</p>
            <h1 class="text-5xl font-extrabold uppercase text-white"><span class="text-red-700">Video</span>teke</h1>
            <p class="text-3xl font-bold text-white">po tvojoj mjeri</p>
            <p v-if="biggerScreen" class="mt-4 font-semibold text-lg text-white">Zabavi se uz najveće naslove domaće i svjetske produkcije<br> putem videoteka Superstar, Apollon, Pickbox NOW, Nick +, FilmBox, AXN NOW i Epic Drama. </p>
            <p v-else class="mt-4 font-semibold text-base text-white">Zabavi se uz najveće naslove domaće i svjetske produkcije<br> putem videoteka Superstar, Apollon, Pickbox NOW, Nick +, FilmBox, AXN NOW i Epic Drama. </p>
          </div>
        </v-col>
        <v-col cols="6">
        </v-col>
      </v-row>
    </v-container>
  </div>
  <div class="superstar">
    <div class="subtitle">Gledaj najbolje i najnovije serije domaće produkcije na jednom mjestu! Superstar videoteku je moguće dokupiti za 4,68 KM/ mjesečno.</div>
  </div>
  <div class="apollon">
    <div class="subtitle">Istraži različite žanrove bez obzira da li si ljubitelj akcije, romantike, komedije ili trilera - Apollon videoteka ima sve! Prepusti se nevjerovatnom izboru naslova iz domaće i holivudske produkcije po povoljnoj cijeni od 6,90KM/mjesečno.</div>
  </div>
  <div class="pickbox">
    <div class="subtitle">Ako tražiš dramu, napete trilere, komedije ili kvalitetne dokumentarce, koji su nastali u produkciji nekih od najvećih svjetskih studija, onda je Pickbox NOW pravi izbor za tebe. Videoteku možeš da dokupiš za 8,78 KM/mjesečno.</div>
  </div>
  <div class="nick">
    <div class="subtitle">U okviru Nick+ videoteke najmlađi mogu da uživaju uz omiljene junake iz najpopularnijih serija i crtanih filmova. Nick+ videoteka uključena je u sve MOVE TV pakete.</div>
  </div>
  <div class="filmbox">
    <div class="subtitle">Uzbudljiva avantura čeka te u okviru FilmBox videoteke - od akcije, preko komedija do napetih drama i jezivih trilera i horora. Pored vrhunskih filmova i serija, uživaj i u više od 100 sati dokumentarnog programa iz DocuBox produkcije. Ne propusti priliku da gledaš najbolji sadržaj na jednom mjestu po akcijskoj cijeni od 4,68 KM mjesečno.</div>
  </div>
  <div class="axn">
    <div class="subtitle">Zaroni u svijet vrhunskih produkcija sa AXN NOW videotekom i uživaj u najboljim igranim i dokumentarnim TV serijama. Videoteka AXN NOW je uključena u sve MOVE TV pakete.</div>
  </div>
  <div class="epicdrama">
    <div class="subtitle">Očekuj širok spektar dramskih tema, od raznih kriminalističkih zapleta, klasičnih književnih djela, preko političkih intriga i moći, do istorijskih priča, ratne epopeje i avanturističkih akcija. Videoteka je uključena u sve MOVE TV pakete.</div>
  </div>
  <div class="history">
    <div class="subtitle">History videoteka je pravo mjesto za sve ljubitelje istorije. Ova videoteka nudi bogate i informativne sadržaje koji otkrivaju prošlost na nov i inspirativan način, kao i dokumentarne kriminalističke serijale iz produkcije Crime & Investigation kanala. Videoteka je uključena u sve MOVE TV pakete.</div>
  </div>
  <div class="telcast">
    <div class="subtitle">Očekuj širok spektar dramskih tema, od raznih kriminalističkih zapleta, klasičnih književnih djela, preko političkih intriga i moći, do istorijskih priča, ratne epopeje i avanturističkih akcija. Videoteka je uključena u sve MOVE TV pakete.</div>
  </div>
</template>

<script>
import {onBeforeMount} from 'vue'

export default {
  setup(){
    onBeforeMount(() => {
      window.scrollTo(0, 0)
    });
  },

  computed:{
      biggerScreen() {
      if(this.$vuetify.display.width >= 960){
        return true;
      }else{
        return false;
      }
      }
    }
}
</script>

<style>
  .videos{
    position: relative;
    height: 380px;
    width: 100%;
    display: flex;
    justify-content: center;
    padding-top: 40px;
  }
  .videos::before{
    content: "";
    background-image: url('@/assets/images/banner.jpg');
    background-size: cover;
    background-position: 50% 20%;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    opacity: 0.75;
    /* filter: grayscale(50%); */
  }

  .videos h1{
    font-family: 'Pero-Bold';
  }
  .videos p{
    font-family: 'Roboto-Light';
  }

  .subtitle {
    background-color: rgba(0,0,0,0.2);
    border-radius: 10px;
    padding: 7px;
    position: absolute;
    bottom: 50px;
    left: 400px;
    right: 400px;
    text-align: center;
    font-size: 20px;
    color: white;
    text-shadow: 2px 2px 2px black;
    font-weight: 300;
}

  @media only screen and (max-width: 1500px){
    .subtitle {
        left: 200px;
        right: 200px;
    }
  }

  @media only screen and (max-width: 450px){
    .subtitle {
        font-size: 12px;
    }
  }

  @media only screen and (max-width: 850px) and (orientation: portrait){
    .subtitle {
        bottom: 50px;
        left: 40px;
        right: 40px;
        font-size: 12px;
    }
  }

  .superstar{
    font-family: 'Roboto-Light';
    position: relative;
    height: 650px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .superstar::before{
    content: "";
    /* background-image: url('https://ontv.ba/resources/images/vod/superstarvod.jpg'); */
    background-image: url('@/assets/images/superstar-banner.jpg');
    background-position: 50% 50%;
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  @media screen and (max-width: 460px){
    .superstar{
    height: 400px;
  }
    .superstar::before{
    background-image: url('@/assets/images/superstar-mobile.jpg');
    background-position: 50% 50%;
  }
  }
  @media screen and (min-width:460px) and (max-width: 960px){
    .superstar{
      height: 500px;
    }
    .superstar::before{
    background-image: url('@/assets/images/superstar-mobile.jpg');
    background-position: 50% 50%;
  }
  }
  @media screen and (min-width:960px) and (max-width: 1000px){
    .superstar{
      height: 450px;
    }
  }
  @media screen and (min-width:1000px) and (max-width: 1300px){
    .superstar{
      height: 450px;
    }
  }
  .nick{
    font-family: 'Roboto-Light';
    position: relative;
    height: 650px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .nick::before{
    content: "";
    /* background-image: url('https://ontv.ba/resources/images/vod/axnnow.jpg'); */
    background-image: url('@/assets/images/nick-banner.jpg');
    background-position: 50% 50%;
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  @media screen and (max-width: 460px){
    .nick{
      height: 400px;
    }
    .nick::before{
      background-image: url('@/assets/images/nick-mobile.jpg');
      background-position: 50% 50%;
    }
  }
  @media screen and (min-width:460px) and (max-width: 760px){
    .nick{
      height: 250px;
    }
  }
  @media screen and (min-width:760px) and (max-width: 1000px){
    .nick{
      height: 350px;
    }
  }
  @media screen and (min-width:1000px) and (max-width: 1300px){
    .nick{
      height: 450px;
    }
  }

  .apollon{
    font-family: 'Roboto-Light';
    position: relative;
    height: 650px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .apollon::before{
    content: "";
    background-image: url('@/assets/images/apollon-banner.jpg');
    background-position: 50% 10%;
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  @media screen and (max-width: 460px){
    .apollon{
      height: 400px;
    }
    .apollon::before{
      background-image: url('@/assets/images/apollon-mobile.jpg');
      background-position: 50% 50%;
    }
  }
  @media screen and (min-width:460px) and (max-width: 760px){
    .apollon{
      height: 250px;
    }
  }
  @media screen and (min-width:760px) and (max-width: 1000px){
    .apollon{
      height: 350px;
    }
  }
  @media screen and (min-width:1000px) and (max-width: 1300px){
    .apollon{
      height: 450px;
    }
  }

  .pickbox{
    font-family: 'Roboto-Light';
    position: relative;
    height: 650px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .pickbox::before{
    content: "";
    background-image: url('@/assets/images/pickbox-banner.jpg');
    background-position: 50% 50%;
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  @media screen and (max-width: 460px){
    .pickbox{
      height: 400px;
    }
    .pickbox::before{
      background-image: url('@/assets/images/pickbox-mobile.jpg');
      background-position: 50% 50%;
    }
  }
  @media screen and (min-width:460px) and (max-width: 760px){
    .pickbox{
      height: 250px;
    }
  }
  @media screen and (min-width:760px) and (max-width: 1000px){
    .pickbox{
      height: 350px;
    }
  }
  @media screen and (min-width:1000px) and (max-width: 1300px){
    .pickbox{
      height: 450px;
    }
  }

  .filmbox{
    font-family: 'Roboto-Light';
    position: relative;
    height: 650px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .filmbox::before{
    content: "";
    background-image: url('@/assets/images/filmbox-banner.jpg');
    background-position: 50% 50%;
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  @media screen and (max-width: 460px){
    .filmbox{
      height: 400px;
    }
    .filmbox::before{
      background-image: url('@/assets/images/filmbox-mobile.jpg');
      background-position: 50% 50%;
    }
  }
  @media screen and (min-width:460px) and (max-width: 760px){
    .filmbox{
      height: 250px;
    }
  }
  @media screen and (min-width:760px) and (max-width: 1000px){
    .filmbox{
      height: 350px;
    }
  }
  @media screen and (min-width:1000px) and (max-width: 1300px){
    .filmbox{
      height: 450px;
    }
  }

  .axn{
    font-family: 'Roboto-Light';
    position: relative;
    height: 650px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .axn::before{
    content: "";
    background-image: url('@/assets/images/axn-banner.jpg');
    background-position: 50% 50%;
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  @media screen and (max-width: 460px){
    .axn{
      height: 400px;
    }
    .axn::before{
      background-image: url('@/assets/images/axn-mobile.jpg');
      background-position: 50% 50%;
    }
  }
  @media screen and (min-width:460px) and (max-width: 760px){
    .axn{
      height: 250px;
    }
  }
  @media screen and (min-width:760px) and (max-width: 1000px){
    .axn{
      height: 350px;
    }
  }
  @media screen and (min-width:1000px) and (max-width: 1300px){
    .axn{
      height: 450px;
    }
  }

  .epicdrama{
    font-family: 'Roboto-Light';
    position: relative;
    height: 650px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .epicdrama::before{
    content: "";
    background-image: url('@/assets/images/epic-drama-banner.jpg');
    background-position: 50% 50%;
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  @media screen and (max-width: 460px){
    .epicdrama{
      height: 400px;
    }
    .epicdrama::before{
      background-image: url('@/assets/images/epic-drama-mobile.jpg');
      background-position: 50% 50%;
    }
  }
  @media screen and (min-width:460px) and (max-width: 760px){
    .epicdrama{
      height: 250px;
    }
  }
  @media screen and (min-width:760px) and (max-width: 1000px){
    .epicdrama{
      height: 350px;
    }
  }
  @media screen and (min-width:1000px) and (max-width: 1300px){
    .epicdrama{
      height: 450px;
    }
  }

  .history{
    font-family: 'Roboto-Light';
    position: relative;
    height: 650px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .history::before{
    content: "";
    background-image: url('@/assets/images/history-banner.jpg');
    background-position: 50% 50%;
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  @media screen and (max-width: 460px){
    .history{
      height: 400px;
    }
    .history::before{
      background-image: url('@/assets/images/history-mobile.jpg');
      background-position: 50% 50%;
    }
  }
  @media screen and (min-width:460px) and (max-width: 760px){
    .history{
      height: 250px;
    }
  }
  @media screen and (min-width:760px) and (max-width: 1000px){
    .history{
      height: 350px;
    }
  }
  @media screen and (min-width:1000px) and (max-width: 1300px){
    .history{
      height: 450px;
    }
  }

  .telcast{
    font-family: 'Roboto-Light';
    position: relative;
    height: 650px;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .telcast::before{
    content: "";
    background-image: url('@/assets/images/telcast-banner.jpg');
    background-position: 50% 50%;
    background-size: cover;
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }

  @media screen and (max-width: 460px){
    .telcast{
      height: 400px;
    }
    .telcast::before{
      background-image: url('@/assets/images/telcast-mobile.jpg');
      background-position: 50% 50%;
    }
  }
  @media screen and (min-width:460px) and (max-width: 760px){
    .telcast{
      height: 250px;
    }
  }
  @media screen and (min-width:760px) and (max-width: 1000px){
    .telcast{
      height: 350px;
    }
  }
  @media screen and (min-width:1000px) and (max-width: 1300px){
    .telcast{
      height: 450px;
    }
  }

</style>