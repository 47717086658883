import { createApp, markRaw } from 'vue'
import App from './App.vue'
import router from './router'
import './assets/tailwind.css'
import 'bootstrap/dist/css/bootstrap.css'
import bootstrap from 'bootstrap/dist/js/bootstrap.bundle.js'
import axios from 'axios'
import VueAxios from 'vue-axios'


// Vuetify
import 'vuetify/styles'
import '@mdi/font/css/materialdesignicons.css'
import { createVuetify } from 'vuetify'
import * as components from 'vuetify/components'
import * as directives from 'vuetify/directives'
import 'material-design-icons-iconfont/dist/material-design-icons.css'
import { createPinia } from 'pinia'

const vuetify = createVuetify({
  components,
  directives,
  icons: {
    defaultSet: 'mdi'
  }
});

const pinia = createPinia();

pinia.use(({ store }) => {
  store.$router = markRaw(router)
});

createApp(App).use(router).use(vuetify).use(bootstrap).use(VueAxios, axios).use(pinia).mount('#app')
